const getConstants = () => {
	return {
        // base_url: 'http://localhost:8000/api',
		base_url: 'https://staging-api.phihomeindonesia.com/api',
		crypto_key: 'AIzaSyANWbkTvHHosHCVOad_EukhcxDbKPt0oDg',
	};
};

const constants = getConstants();

export { constants };