import Axios from 'axios'
import { constants } from '@/config/constant'

const tableArea = async (context, payload) => {
    const url = constants.base_url + '/v1/area/table'
    let response = await Axios.get(url, {headers:{'custom_loading': true}, params: payload})

    if(response.data.status){
        context.commit('SET_AREA_TABLE', response.data)
    }

    return response.data
}

const createArea = async (context, payload) => {
    const url = constants.base_url + '/v1/area/create'
    let response = await Axios.post(url, payload, {headers:{'Content-Type': 'multipart/form-data'}})

    return response.data
}

const updateArea = async (context, payload) => {
    const url = constants.base_url + '/v1/area/update/' + payload.id
    let response = await Axios.patch(url, payload.data)

    return response.data
}

const deleteArea = async (context, payload) => {
    const url = constants.base_url + '/v1/area/delete/' + payload
    let response = await Axios.delete(url)

    return response.data
}

const getProvinceOptions = async (context, payload) => {
    const url = constants.base_url + '/v1/area/province'
    let response = await Axios.get(url, {params: payload})

    if(response.data.status){
        context.commit('SET_PROVINCE_OPTIONS', response.data)
    }

    return response.data
}

const getRegencyOptions = async (context, payload) => {
    const url = constants.base_url + '/v1/area/regency'
    let response = await Axios.get(url, {params: payload})

    if(response.data.status){
        context.commit('SET_REGENCY_OPTIONS', response.data)
    }

    return response.data
}

const getDistrictOptions = async (context, payload) => {
    const url = constants.base_url + '/v1/area/district'
    let response = await Axios.get(url, {params: payload})

    if(response.data.status){
        context.commit('SET_DISTRICT_OPTIONS', response.data)
    }

    return response.data
}

const resetAreaForm = async (context) => {
    context.commit('RESET_AREA_FORM')
}

const resetRegencyOptions = async (context) => {
    context.commit('RESET_REGENCY_OPTIONS')
}

const resetDistrictOptions = async (context) => {
    context.commit('RESET_DISTRICT_OPTIONS')
}

export {
    tableArea ,
    createArea,
    updateArea,
    deleteArea,
    getProvinceOptions,
    getRegencyOptions,
    getDistrictOptions,
    resetAreaForm,
    resetRegencyOptions,
    resetDistrictOptions,
}