<template>
    <modal-component :modal_status="modal">
        <template v-slot:modal_header>
            <div class="modal-title custom-font-lg custom-font-bold">{{ is_edit ? 'Ubah' : 'Tambah' }} Data Layanan</div>
            <button type="button" class="btn-close" @click="close_modal(false)"/>
        </template>
        <template v-slot:modal_body>
            <div class="row">
                <div class="col-12 mb-3" :class="validation.firstError('form.name') ? 'custom-form-error' : ''">
                    <label class="form-label">Nama Layanan</label>
                    <input type="text" class="form-control custom-form-control" placeholder="Nama Layanan" v-model="form.name">
                    <div class="custom-form-error-message">{{ validation.firstError('form.name') }}</div>
                </div>
                <div class="col-12 mb-3" :class="validation.firstError('form.icon') ? 'custom-form-error' : ''">
                    <label>Icon</label>
                    <file-input
                        :reference="'icon'"
                        :multiple="true"
                        :maximum="1"
                        :max_size="1024000"
                        :accept="'image/png, image/jpg, image/jpeg'"
                        :extensions="['png', 'jpg', 'jpeg']"
                        :resize="{width: 256, height: 256}"
                        @set_queue="queue_files"
                        :old_file="local_file"
                        :old_delete="true"
                    />
                    <div class="custom-form-error-message">{{ validation.firstError('form.icon') }}</div>
                </div>
            </div>
        </template>
        <template v-slot:modal_footer>
            <button type="button" class="btn custom-btn-outline-02" @click="close_modal(false)">Tutup</button>
            <button type="button" class="btn custom-btn-02" @click="submit()">{{ is_edit ? 'Simpan' : 'Tambah' }}</button>
        </template>
    </modal-component>
</template>

<script>
    import ModalComponent from '@/components/external/ModalComponent'
    import FileInput from '@/components/external/input/FileInput'
    import { mapGetters } from 'vuex'
    import { Validator } from 'simple-vue3-validator'
    import Swal from 'sweetalert2'

	export default {
        emits: ['close'],
        components: {
            'modal-component': ModalComponent,
            'file-input': FileInput,
        },
        data() {
            return {
                modal: false,
                is_edit: null,
                local_file: [],
            }
        },
        computed: {
            ...mapGetters({
                form: 'service/getServiceForm',
            }),
        },
        validators: {
            'form.name': function(value){
                if(this.form_modal){
                    return Validator.value(value).required('Wajib di isi.')
                }
            },
            'form.icon': function(value){
                if(this.form_modal){
                    return Validator.custom(function () {
                        if (!Validator.isEmpty(value)) {
                            for(var i = 0; i < value.length; i++) {
                                if (value[i].error != '') {
                                    return 'Invalid file format'
                                }
                            }
                        }
                    })
                }
            },
        },
        methods: {
            open_modal(data){
                if(data){
                    this.is_edit = data.id
                    this.form.name = data.name

                    if(data.icon != 'default.png'){
                        this.local_file.push({
                            error: '',
                            file_status: this.$root.copy(data.icon),
                            file_name: this.$root.copy(data.icon),
                            file_url: this.$root.copy(data.icon_url),
                        })
                    }
                }
                this.modal = true
            },
            close_modal(value){
                this.modal = false
                this.is_edit = null
                this.local_file = []
                this.validation.reset()
                this.$store.dispatch('service/resetServiceForm')
                this.$emit('close', value)
            },
            queue_files(data){
                if(data.files.length > 0){
                    let files = []
                    for (let i = 0; i < data.files.length; ++i) {
                        if(data.files[i].file){
                            files.push({
                                error: data.files[i].error,
                                file: new File([data.files[i].file], data.files[i].file.name, {type: data.files[i].file.type})
                            })
                        }
                        else{
                            files.push({
                                error: data.files[i].error,
                                file_status: data.files[i].file_status,
                                file_name: data.files[i].file_name,
                                file_url: data.files[i].file_url
                            })
                        }
                    }
                    this.form[data.ref] = files
                }
                else{
                    this.form[data.ref] = null
                }
            },
            submit(){
                let parent = this
                parent.$validate().then(function (success){
                    if(success){
                        let formData = new FormData();
                        formData.append('name', parent.form.name)

                        if(parent.form.icon){
                            for(let i= 0; i < parent.form.icon.length; i++) {
                                if(parent.form.icon[i].file){
                                    formData.append("icon", parent.form.icon[i].file, parent.form.icon[i].file.name)
                                }
                                else{
                                    formData.append('icon', parent.form.icon[i].file_status)
                                }
                            }
                        }

                        if(parent.is_edit){
                            let data = {
                                id: parent.is_edit,
                                data: formData
                            }
                            parent.$store.dispatch('service/updateService', data).then((response) => {
                                if(response.status){
                                    parent.close_modal(true)
                                    Swal.fire({
                                        icon: 'success',
                                        title: 'Berhasil mengubah data',
                                        confirmButtonColor: parent.$root.swal('confirmButtonColor'),
                                        confirmButtonText: parent.$root.swal('doneButtonText'),
                                    })
                                }
                            })
                        }
                        else{
                            parent.$store.dispatch('service/createService', formData).then((response) => {
                                if(response.status){
                                    parent.close_modal(true)
                                    Swal.fire({
                                        icon: 'success',
                                        title: 'Berhasil menambah data',
                                        confirmButtonColor: parent.$root.swal('confirmButtonColor'),
                                        confirmButtonText: parent.$root.swal('doneButtonText'),
                                    })
                                }
                            })
                        }
                    }
                    else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: parent.$root.swal('errorValidationText'),
                            confirmButtonColor: parent.$root.swal('errorButtonColor'),
                            confirmButtonText: parent.$root.swal('errorButtonText'),
                        })
                    }
                })
            },
        }
	}
</script>