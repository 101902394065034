<template>
    <content-component>
        <template v-slot:content_body>
            <div class="row">
                <div class="col-12 col-lg-6 mb-3">
                    <div class="custom-badge-01">
                        <div class="custom-badge-label">
                            <i class="fas fa-user"></i>
                            Total Pengguna
                        </div>
                        <div class="custom-badge-counter">{{ user_count }}</div>
                    </div>
                </div>
                <div class="col-12 col-lg-6 mb-3">
                    <div class="custom-badge-01">
                        <div class="custom-badge-label">
                            <i class="fas fa-clipboard"></i>
                            Total Survey
                        </div>
                        <div class="custom-badge-counter">{{ survey_total }}</div>
                    </div>
                </div>
                <div class="col-12 col-lg-3 mb-3">
                    <div class="custom-badge-01">
                        <div class="custom-badge-label">
                            <i class="fas fa-times"></i>
                            Belum Dibayar
                        </div>
                        <div class="custom-badge-counter">{{ survey_not_paid_count }}</div>
                    </div>
                </div>
                <div class="col-12 col-lg-3 mb-3">
                    <div class="custom-badge-01">
                        <div class="custom-badge-label">
                            <i class="fas fa-money-bill"></i>
                            Sudah Dibayar
                        </div>
                        <div class="custom-badge-counter">{{ survey_paid_count }}</div>
                    </div>
                </div>
                <div class="col-12 col-lg-3 mb-3">
                    <div class="custom-badge-01">
                        <div class="custom-badge-label">
                            <i class="fas fa-hammer"></i>
                            Dalam Pengerjaan
                        </div>
                        <div class="custom-badge-counter">{{ survey_progress_count }}</div>
                    </div>
                </div>
                <div class="col-12 col-lg-3 mb-3">
                    <div class="custom-badge-01">
                        <div class="custom-badge-label">
                            <i class="fas fa-check"></i>
                            Selesai
                        </div>
                        <div class="custom-badge-counter">{{ survey_done_count }}</div>
                    </div>
                </div>
                <!-- <div class="col-12">
                    <bar-chart-component :id="'survey_chart'" :data="survey_chart_data" :options="chart_options" v-if="survey_chart"/>
                </div> -->
            </div>
        </template>
    </content-component>
</template>

<script>
	import ContentComponent from '@/components/external/ContentComponent'
    // import BarChartComponent from '@/components/external/chart/BarChartComponent'

	export default {
        components: {
            'content-component': ContentComponent,
            // 'bar-chart-component': BarChartComponent,
        },
        data() {
            return {
                user_count: 0,
                survey_total: 0,
                survey_not_paid_count: 0,
                survey_paid_count: 0,
                survey_progress_count: 0,
                survey_done_count: 0,
                // survey_chart: false,
                // survey_chart_data: {
                //     labels: ['Belum Dibayar', 'Sudah Dibayar', 'Dalam Pengerjaan', 'Selesai'],
                //     datasets: [
                //         {
                //             label: 'Jenis Survey',
                //             backgroundColor: '#1D66AA',
                //             data: []
                //         }
                //     ]
                // },
                // chart_options: {
                //     responsive: true,
                //     maintainAspectRatio: false,
                //     scales: {
                //         yAxes: {
                //             ticks: {
                //                 min: 0,
                //                 stepSize: 10,
                //                 reverse: false,
                //                 beginAtZero: true
                //             }
                //         }
                //     }
                // }
            }
        },
        created(){
            this.init()
        },
        methods: {
            init(){
                this.$store.dispatch('user/countUser').then((response) => {
                    if(response.status){
                        this.user_count = response.data
                    }
                })
                this.$store.dispatch('survey/countSurvey').then((response) => {
                    if(response.status){
                        this.survey_not_paid_count = response.data.not_paid
                        this.survey_paid_count = response.data.paid
                        this.survey_progress_count = response.data.waiting_to_be_done
                        this.survey_done_count = response.data.already_done
                        this.survey_total = response.data.total

                        // for(let el in response.data){
                        //     if(Object.prototype.hasOwnProperty.call(response.data, el)) {
                        //         this.survey_chart_data.datasets[0].data.push(response.data[el]);
                        //         this.survey_count += parseFloat(response.data[el])
                        //     }
                        // }
                        // this.survey_chart = true
                    }
                })
            },
        }
	}
</script>