const getShow = (state) => {
    return state.show
}

const getShowForm = (state) => {
    return state.show_form
}

export {
    getShow,
    getShowForm,
};