import Axios from 'axios'
import { constants } from '@/config/constant'

const notificationSurveyCount = async () => {
    const url = constants.base_url + '/v1/notification/survey/count'
    let response = await Axios.get(url, {headers:{'custom_loading': true}})

    return response.data
}

const notificationUpdateSurveyCount = async (context, payload) => {
    const url = constants.base_url + '/v1/notification/survey/count/update'
    let response = await Axios.patch(url, payload)

    return response.data
}

export {
    notificationSurveyCount,
    notificationUpdateSurveyCount,
}