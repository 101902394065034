const getDefaultState = () => {
	return {
		faq_table: [],
		faq_form: {
			question: null,
			answer: null,
		}
	}
}

const default_state = getDefaultState()

export { default_state }