const getService = (state) => {
    return state.service
}

const getServiceFavorite = (state) => {
    return state.service_favorite
}

const getServiceTable = (state) => {
    return state.service_table
}

const getServiceDetail = (state) => {
    return state.service_detail
}

const getServiceForm = (state) => {
    return state.service_form
}

const getProblemForm = (state) => {
    return state.problem_form
}

const getServiceDetailForm = (state) => {
    return state.service_detail_form
}

const getServicePriceForm = (state) => {
    return state.service_price_form
}

export {
    getService,
    getServiceFavorite,
    getServiceTable,
    getProblemForm,
    getServiceDetail,
    getServiceForm,
    getServiceDetailForm,
    getServicePriceForm,
};