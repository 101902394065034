const SET_ABOUT = (state, payload) => {
    state.about = payload.data
}

const RESET_ABOUT_FORM = (state) => {
    state.about_form = {
        title: null,
        description: null,
        image: null,
    }
}

export {
    SET_ABOUT,
    RESET_ABOUT_FORM,
}