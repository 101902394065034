<template>
    <content-component>
        <template v-slot:table_create_button>
            <button class="btn custom-btn-02 w-100" @click="open_form_modal()">
                <i class="fas fa-plus"></i>
                Wilayah
            </button>
        </template>
        <template v-slot:content_body>
            <table-component :attributes="table_attributes" :table_header="table_header" :table="table" @get_table="get_table">
                <template v-slot:table_body>
                    <tr v-for="(data, index) in table_data" :key="index">
                        <td>{{ data.province_name }}</td>
                        <td>{{ data.regency_name }}</td>
                        <td class="text-center">
                            <div class="d-flex justify-content-around">
                                <!-- <button type="button" class="btn custom-btn-unborder-02 py-0" @click="open_form_modal(data)">
                                    <i class="fas fa-pen"></i>
                                </button> -->
                                <button type="button" class="btn custom-btn-unborder-02 py-0" @click="delete_data(data.id)">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </template>
            </table-component>
        </template>
    </content-component>
    <area-form-modal ref="areaFormModal" @close="close_modal"/>
</template>

<script>
	import ContentComponent from '@/components/external/ContentComponent'
    import TableComponent from '@/components/external/TableComponent'
    import AreaFormModal from './AreaFormModal'
    import { mapGetters } from 'vuex'
    import Swal from 'sweetalert2'

	export default {
        components: {
            'content-component': ContentComponent,
            'table-component': TableComponent,
            'area-form-modal': AreaFormModal,
        },
        data() {
            return {
                table_header: [
                    { name: 'province', title: 'Provinsi', width: '45%', sort: true },
                    { name: 'regency', title: 'Kabupaten', width: '45%', sort: false },
                    { name: '', title: '', width: '10%', sort: false },
                ],
                table_attributes: {
                    sort_by: 'province',
                    sort_type: 'asc',
                    search: null,
                    page: 1,
                },
                table_data: [],
            }
        },
        computed: {
            ...mapGetters({
                table: 'area/getAreaTable',
            }),
        },
        created(){
            this.get_table(this.table_attributes)
        },
        methods: {
            get_table(value){
                this.$store.dispatch('area/tableArea', value).then((response) => {
                    if(response.status){
                        if(value.page == 1){
                            this.table_data = this.table.data
                        }
                        else{
                            this.table_data = [ ...this.table_data, ...this.table.data]
                        }
                        this.table_attributes = value
                    }
                })
            },
            open_form_modal(value){
                this.$refs.areaFormModal.open_modal(value)
            },
            close_modal(value){
                if(value){
                    this.get_table(this.table_attributes)
                }
            },
            delete_data(id){
                let parent = this
                Swal.fire({
                    icon: 'warning',
                    title: 'Apakah anda yakin ingin menghapus data ini?',
                    reverseButtons: true,
                    showCancelButton: true,
                    cancelButtonText: parent.$root.swal('cancelButtonText'),
                    confirmButtonColor: parent.$root.swal('errorButtonColor'),
                    confirmButtonText: parent.$root.swal('deleteButtonText'),
                }).then((result) => {
                    if (result.isConfirmed) {
                        parent.$store.dispatch('area/deleteArea', id).then((response) => {
                            if(response.status){
                                parent.get_table(parent.table_attributes)
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Berhasil menghapus data',
                                    confirmButtonColor: parent.$root.swal('confirmButtonColor'),
                                    confirmButtonText: parent.$root.swal('doneButtonText'),
                                })
                            }
                        })
                    }
                })
            },
        }
	}
</script>