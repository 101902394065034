import Axios from 'axios'
import crypto from 'crypto-js'
import Swal from 'sweetalert2'
import { constants } from '@/config/constant'
import { swal } from '@/config/helper'

export function initialize(router, store){
	router.beforeEach((to, from, next) => {
		const requireAuth = to.meta.auth
		const isAuthenticated = localStorage.getItem('token')

		if(['sign_in'].includes(to.name) && isAuthenticated){
			next('/dashboard')
		}
		else if(requireAuth && !isAuthenticated){
			next('/')
		}
		else{
			next()
		}
	})

	Axios.interceptors.request.use((config) => {
		const token = localStorage.getItem('token') ? crypto.AES.decrypt(localStorage.getItem('token'), constants.crypto_key).toString(crypto.enc.Utf8) : null

		config.headers.custom_loading ? delete config.headers.custom_loading : store.dispatch('utilities/isLoading', true)

		if(token){
			config.headers.Authorization = `Bearer ${token}`
		}
		
		return config
	}, (error) => {
		store.dispatch('utilities/isLoading', false)
		return Promise.reject(error.response)
	})

	Axios.interceptors.response.use((response) => {
		store.dispatch('utilities/isLoading', false)
		return response
	}, async function (error) {
		if(error.config.url !== `${constants.base_url}/v1/logout`){
			if(error.response.status === 401){
				const originalRequest = error.config

				if(!store.getters['utilities/isRefreshToken']){
					store.dispatch('utilities/isRefreshToken', true)

					try {
						const response = await Axios.post(constants.base_url + '/v1/refresh_token', {
							refresh_token: crypto.AES.decrypt(localStorage.getItem('refresh_token'), constants.crypto_key).toString(crypto.enc.Utf8)
						})
		
						localStorage.setItem('token', crypto.AES.encrypt(response.data.data.access_token, constants.crypto_key).toString())
						localStorage.setItem('refresh_token', crypto.AES.encrypt(response.data.data.refresh_token, constants.crypto_key).toString())
		
						store.dispatch('utilities/isRefreshToken', false)
						return Axios(originalRequest)
					} catch (err) {
						localStorage.removeItem('token')
						localStorage.removeItem('refresh_token')
						localStorage.removeItem('user')
						store.commit('auth/RESET_USER')
						router.push({name: 'sign_in'})
					}
				}
				else{
					const refresh_interval = setInterval(() => {
						if(!store.getters['utilities/isRefreshToken']){
							clearInterval(refresh_interval)
							return Axios(originalRequest)
						}
					}, 100)
				}
			}
			else if(error.response.status === 403){
				localStorage.removeItem('token')
				localStorage.removeItem('refresh_token')
				localStorage.removeItem('user')
				store.commit('auth/RESET_USER')
				router.push({name: 'sign_in'})
			}
			else{
				if(error.response.data !== undefined){
					Swal.fire({
						icon: 'error',
						title: 'Error',
						text: error.response.data.message,
						confirmButtonColor: swal('errorButtonColor'),
						confirmButtonText: swal('errorButtonText'),
					})
				}
				else{
					Swal.fire({
						icon: 'error',
						title: 'Error',
						text: error.response,
						confirmButtonColor: swal('errorButtonColor'),
						confirmButtonText: swal('errorButtonText'),
					})
				}
			}
		}
		store.dispatch('utilities/isLoading', false)
		return Promise.reject(error.response)
	})
}