const getUser = (state) => {
	return state.user;
}

const getSignInForm = (state) => {
	return state.sign_in_form;
}

const getChangePasswordForm = (state) => {
	return state.change_password_form;
}

export {
    getUser,
    getSignInForm,
    getChangePasswordForm,
};