<template>
    <div class="custom-upload-wrapper">
        <!-- Drag & drop mask -->
        <div v-show="$refs[reference] && $refs[reference].dropActive" class="drop-active">
            <h3>Drop files to upload</h3>
        </div>
        <!-- File preview -->
        <div :class="checkError(files) ? 'custom-form-error custom-upload-card mb-2' : 'custom-upload-card mb-2'">
            <div class="custom-upload-preview-wrapper" v-if="files.length">
                <div class="custom-upload-preview-card" v-for="(file, index) in files" :key="file.id">
                    <template v-if="file.file_status">
                        <div class="custom-upload-error" v-if="file.file_status == 'delete'">
                            Delete old file
                        </div>
                        <div class="custom-upload-thumbnail" style="cursor: pointer;" v-viewer="{toolbar: false, navbar: false, keyboard: false}" v-else>
                            <img :src="file.file_url" alt="old-image">
                        </div>
                        <div class="custom-upload-preview-button" v-if="old_delete">
                            <button type="button" class="btn custom-btn-02 py-1 px-2" @click.prevent="change_old_file(index)">
                                <i class="fas fa-undo-alt" v-if="file.file_status == 'delete'"></i>
                                <i class="fas fa-trash" v-else></i>
                            </button>
                        </div>
                    </template>
                    <template v-else>
                        <div class="custom-upload-error" v-if="file.error">
                            {{ file.error }}
                        </div>
                        <div class="custom-upload-thumbnail" style="cursor: pointer;" v-viewer="{toolbar: false, navbar: false, keyboard: false}" v-else>
                            <img v-if="file.thumb" :src="file.thumb" alt="image-preview"/>
                        </div>
                        <div class="custom-upload-preview-button">
                            <button type="button" class="btn custom-btn-02 py-1 px-2" v-if="file.type.substr(0, 6) === 'image/' && !file.error" @click.prevent="open_edit_modal(file)">
                                <i class="fas fa-crop-alt"></i>
                            </button>
                            <button type="button" class="btn custom-btn-02 py-1 px-2" @click.prevent="remove(index, file)">
                                <i class="fas fa-trash"></i>
                            </button>
                        </div>
                    </template>
                </div>
            </div>
            <!-- if empty -->
            <div class="custom-upload-start-message" v-else>
                <div>
                    <i class="fas fa-cloud-upload-alt fa-3x mb-1"></i>
                    <h6>Drop files here or click "Select Files" button to upload.</h6>
                    <h6><template v-if="maximum">Max files: {{ maximum }}. </template><template v-if="max_size">Max Size: {{ formatBytes(max_size) }}.</template></h6>
                    <h6 v-if="min_compress">(Min Image Resize: {{ min_compress.width + ' X ' + min_compress.height }})</h6>
                    <h6 v-if="max_compress">(Max Image Resize: {{ max_compress.width + ' X ' + max_compress.height }})</h6>
                    <h6 v-if="resize">(Image Resize to: {{ resize.width + ' X ' + resize.height }})</h6>
                </div>
            </div>
        </div>
        <!-- Upload button -->
        <upload-component
            :ref="reference"
            :input-id="reference"
            :accept="accept"
            :maximum="max_files"
            :multiple="multiple"
            :drop="drop"
            v-model="files"
            @input-filter="inputFilter"
            @input-file="inputFile"
            class="btn custom-btn-02 mt-2"
        >
            <i class="fa fa-plus"></i>
            Select Files
        </upload-component>
        <modal-component :modal_status="edit_modal" :modal_size="'modal-lg'">
            <template v-slot:modal_header>
                <h5 class="modal-title">Edit Image</h5>
                <button type="button" class="btn-close" @click="close_edit_modal()"/>
            </template>
            <template v-slot:modal_body>
                <div class="overflow-auto" style="max-height: 60vh;">
                    <div class="custom-upload-edit-image" v-if="edit_modal">
                        <img :src="edit_file.blob" ref="editImage"/>
                    </div>
                </div>
                <div class="d-flex mt-2">
                    <div class="btn-group" role="group">
                        <button type="button" class="btn custom-btn-02" @click="edit_file.cropper.setDragMode('move')"><i class="fas fa-arrows-alt"></i></button>
                        <button type="button" class="btn custom-btn-02" @click="edit_file.cropper.setDragMode('crop')"><i class="fas fa-crop-alt"></i></button>
                    </div>
                    <div class="btn-group mx-2" role="group">
                        <button type="button" class="btn custom-btn-02" @click="edit_file.cropper.rotate(-90)"><i class="fas fa-undo"></i></button>
                        <button type="button" class="btn custom-btn-02" @click="edit_file.cropper.rotate(-90)"><i class="fas fa-redo"></i></button>
                    </div>
                    <div class="btn-group" role="group">
                        <button type="button" class="btn custom-btn-02" @click="edit_file.cropper.clear()"><i class="fas fa-times"></i></button>
                        <button type="button" class="btn custom-btn-02" @click="edit_file.cropper.reset()"><i class="fas fa-sync"></i></button>
                    </div>
                </div>
            </template>
            <template v-slot:modal_footer>
                <button type="button" class="btn custom-btn-outline-02" @click="close_edit_modal()">Close</button>
                <button type="button" class="btn custom-btn-02" @click="save_edit()">Save changes</button>
            </template>
        </modal-component>
    </div>
</template>

<script>
    import VueUploadComponent from 'vue-upload-component'
    import Compressor from 'compressorjs'
    import Cropper from 'cropperjs'
    import ModalComponent from '../ModalComponent.vue'
    import 'cropperjs/dist/cropper.css'

    export default {
        props: [
            'reference',
            'multiple',
            'dropable',
            'max_size',
            'maximum',
            'minimum',
            'accept',
            'extensions',
            'min_compress',
            'max_compress',
            'resize',
            'aspect_ratio',
            'old_file',
            'old_delete'
        ],
        components: {
            'upload-component': VueUploadComponent,
            'modal-component': ModalComponent,
        },
        data(){
            return {
                files: [],
                max_files: 1,
                drop: true,
                edit_modal: false,
                edit_file: null
            }
        },
        watch: {
            edit_modal(value){
                if(value){
                    this.$nextTick( () => {
                        if(!this.$refs.editImage){
                            return
                        }
                        let cropper = new Cropper(this.$refs.editImage, {
                            autoCrop: false,
                            aspectRatio: this.aspect_ratio ? this.aspect_ratio.width / this.aspect_ratio.height : undefined,
                        })
                        this.edit_file = {
                            ...this.edit_file,
                            cropper
                        }
                    })
                }
            },
            old_file: {
				deep: true,
				handler: function(newData){
                    if(newData.length > 0){
                        this.files = this.$root.copy(newData)
                    }
				}
			},
        },
        created() {
            this.init()
        },
        methods: {
            init(){
                if(this.old_file.length > 0){
                    this.files = this.$root.copy(this.old_file)
                }
                if(this.maximum){
                    if(this.maximum > 1){
                        this.max_files = this.maximum + this.old_file.length
                    }
                    else{
                        this.max_files = this.$root.copy(this.maximum)
                    }
                }
                if(this.dropable == true || this.dropable == false){
                    this.drop = this.$root.copy(this.dropable)
                }
            },
            inputFilter(newFile, oldFile, prevent){
                let parent = this
                if(newFile && !oldFile){
                    // Filter system files or hide files
                    if(/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)){
                        return prevent()
                    }
                    // Filter php html js file
                    if(/\.(php5?|html?|jsx?)$/i.test(newFile.name)){
                        return prevent()
                    }
                    // Filter file extension
                    if(newFile.file && newFile.error === ''){
                        let extension = newFile.name.split('.').pop().toLowerCase()
                        if(!parent.extensions.includes(extension)){
                            newFile.error = 'Invalid file format.'
                            parent.return_error(newFile)
                        }
                    }
                    // Filter file size
                    if(newFile.file && newFile.error === ''){
                        if(newFile.size > parent.max_size){
                            newFile.error = 'Maximum file size is ' + parent.formatBytes(parent.max_size) + '.'
                            parent.return_error(newFile)
                        }
                    }
                    // Automatic image compression and resize
                    if (newFile.file && newFile.error === '' && newFile.type.substr(0, 6) === 'image/') {
                        new Compressor(newFile.file, {
                            quality: 0.9,
                            minWidth: parent.min_compress ? parent.min_compress.width : 0,
                            minHeight: parent.min_compress ? parent.min_compress.height : 0,
                            maxWidth: parent.max_compress ? parent.max_compress.width : Infinity,
                            maxHeight: parent.max_compress ? parent.max_compress.height : Infinity,
                            resize: parent.resize ? 'cover' : 'none',
                            width: parent.resize ? parent.resize.width : undefined,
                            height: parent.resize ? parent.resize.height : undefined,
                            
                            success(file) {
                                parent.$refs[parent.reference].update(newFile, { error: '', file, size: file.size, type: file.type })
                            },
                            error() {
                                parent.$refs[parent.reference].update(newFile, { error: 'Failed to compress image.' })
                            },
                        })
                    }
                }
                // Create a blob field and thumbnails
                if(newFile && newFile.error === '' && newFile.file && (!oldFile || newFile.file !== oldFile.file)){
                    newFile.blob = ''
                    newFile.thumb = ''
                    let URL = (window.URL || window.webkitURL)
                    if(URL){
                        newFile.blob = URL.createObjectURL(newFile.file)
                    }
                    if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
                        newFile.thumb = newFile.blob
                    }
                }
                // Create image resolution
                if(newFile && newFile.error === '' && newFile.type.substr(0, 6) === 'image/' && newFile.blob && (!oldFile || newFile.blob !== oldFile.blob)){
                    let img = new Image();
                    img.onload = () => {
                        parent.$refs[parent.reference].update(newFile, {error: '', height: img.height, width: img.width})
                    } 
                    img.onerror = () => {
                        parent.$refs[parent.reference].update(newFile, { error: 'Failed to parsing image resolution.'}) 
                    }
                    img.src = newFile.blob
                }
            },
            inputFile(newFile, oldFile){
                if(newFile && oldFile){
                    // update
                    this.$emit('set_queue', {ref: this.reference, files: this.files})

                    if(newFile.active && !oldFile.active){
                        // beforeSend
                    }
                    if(newFile.progress !== oldFile.progress){
                        // progress
                    }
                    if(newFile.error && !oldFile.error){
                        // error
                    }
                    if(newFile.success && !oldFile.success){
                        // success
                    }
                }
            },
            checkError(files){
                for(var i = 0; i < files.length; i++) {
                    if (files[i].error != '') {
                        return true
                    }
                }

                return false
            },
            formatBytes(bytes, separator = '', postFix = '') {
                if (bytes) {
                    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
                    const i = Math.min(parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10), sizes.length - 1);
                    return `${(bytes / (1024 ** i)).toFixed(i ? 1 : 0)}${separator}${sizes[i]}${postFix}`;
                }
                return 'n/a';
            },
            open_edit_modal(file){
                this.edit_file = { ...file }
                this.edit_modal = true
            },
            close_edit_modal(){
                this.edit_modal = false
            },
            save_edit(){
                let parent = this
                let edited_file = null
                if(parent.edit_file.cropper){
                    let binStr = atob(parent.edit_file.cropper.getCroppedCanvas().toDataURL(parent.edit_file.type).split(',')[1])
                    let arr = new Uint8Array(binStr.length)
                    for (let i = 0; i < binStr.length; i++) {
                        arr[i] = binStr.charCodeAt(i)
                    }
                    edited_file = new File([arr], parent.edit_file.name, { type: parent.edit_file.type })
                }

                new Compressor(edited_file, {
                    quality: 0.8,
                    minWidth: parent.min_compress ? parent.min_compress.width : 0,
                    minHeight: parent.min_compress ? parent.min_compress.height : 0,
                    maxWidth: parent.max_compress ? parent.max_compress.width : Infinity,
                    maxHeight: parent.max_compress ? parent.max_compress.height : Infinity,
                    resize: parent.resize ? 'cover' : 'none',
                    width: parent.resize ? parent.resize.width : undefined,
                    height: parent.resize ? parent.resize.height : undefined,
                    
                    success(file) {
                        parent.$refs[parent.reference].update(parent.edit_file.id, {file, type: file.type, size: file.size})
                    },
                    error() {
                        parent.$refs[parent.reference].update(parent.edit_file.id, { error: 'Failed to compress image.' })
                    },
                })

                parent.edit_modal = false
            },
            remove(index, file) {
                this.$refs[this.reference].remove(file)
                
                // replace single old file (not working for multiple)
                if(this.maximum == 1 && this.old_file && this.old_file.length > 0){
                    this.files.splice(index, 0, this.$root.copy(this.old_file[index]))
                }

                this.$emit('set_queue', {ref: this.reference, files: this.files})
            },
            change_old_file(index){
                if(this.files[index].file_status == 'delete'){
                    this.files[index].file_status = this.files[index].file_name
                }
                else{
                    this.files[index].file_status = 'delete'
                }
                
                this.$emit('set_queue', {ref: this.reference, files: this.files})
            },
            return_error(file){
                let data = {
                    ref: this.reference,
                    files: []
                }
                
                data.files.push(file)
                
                this.$emit('set_queue', data)
            }
        }
    }
</script>