const SET_SETTING = (state, payload) => {
    state.setting = {
        survey_price: payload.survey_price,
        app_description: payload.app_description,
        admin_phone: payload.admin_phone,
        instruction: payload.instruction,
        customer_service: payload.customer_service,
        download_link: payload.download_link,
        youtube_link: payload.youtube_link,
        facebook_link: payload.facebook_link,
        tiktok_link: payload.tiktok_link,
        instagram_link: payload.instagram_link,
    }
}

export {
    SET_SETTING,
}