<template>
	<content-component>
		<template v-slot:content_body>
			<div class="row" v-if="detail">
				<div class="col-12">
					<div class="custom-text-wrapper mb-4">
						<div class="row">
							<div class="col-7 col-lg-9 mb-3">
								<div class="custom-font-sm custom-font-color-secondary mb-1">1.Status</div>
								<div class="custom-alert alert-danger" v-if="detail.status == 'not_paid'">Belum Dibayar</div>
								<div class="custom-alert alert-primary" v-if="detail.status == 'paid'">Sudah Dibayar</div>
								<div class="custom-alert alert-warning" v-if="detail.status == 'waiting_to_be_done'">Dalam Pengerjaan</div>
								<div class="custom-alert alert-success" v-if="detail.status == 'already_done'">Selesai</div>
							</div>
							<div class="col-5 col-lg-3 mb-3">
								<button type="button" class="btn btn-sm custom-btn-02 mt-4 w-100" @click="update_paid()" v-if="detail.status == 'not_paid'">
									<i class="fas fa-money-bill"></i>
									Bayar
								</button>
								<button type="button" class="btn btn-sm custom-btn-02 mt-4 w-100" @click="update_progress()" v-if="detail.status == 'paid'">
									<i class="fas fa-hammer"></i>
									Kerjakan
								</button>
								<button type="button" class="btn btn-sm custom-btn-02 mt-4 w-100" @click="open_complete_modal('create')" v-if="detail.status == 'waiting_to_be_done'">
									<i class="fas fa-check-circle"></i>
									Selesai
								</button>
								<button type="button" class="btn btn-sm custom-btn-02 mt-4 w-100" @click="open_complete_modal('edit')" v-if="detail.status == 'already_done'">
									<i class="fas fa-pen"></i>
									Ubah
								</button>
							</div>
							<div class="col-12 col-lg-6 mb-3">
								<div class="custom-text-group">
									<label>2.Layanan</label>
									<p>{{ detail.service.name }}</p>
								</div>
							</div>
							<div class="col-12 col-lg-6 mb-3">
								<div class="custom-text-group">
									<label>3.Pembayaran</label>
									<p>{{ detail.payments.length > 1 ? 'Transfer': 'Tunai' }}</p>
								</div>
							</div>
							<div class="col-12 col-lg-6 mb-3">
								<div class="custom-text-group h-100">
									<label>4.Tanggal Survey</label>
									<p>{{ date_format(detail.appointment_date) }}</p>
								</div>
							</div>
							<div class="col-12 col-lg-6 mb-3">
								<div class="custom-text-group h-100">
									<label>5.Jam Survey</label>
									<p>{{ hour_format(detail.appointment_date) }}</p>
								</div>
							</div>
							<div class="col-12 mb-3">
								<div class="custom-text-group">
									<div class="row">
										<div class="col-7 col-lg-9">
											<label class="mt-2">6.Lokasi Survey</label>
										</div>
										<div class="col-5 col-lg-3">
											<button type="button" class="btn btn-sm custom-btn-02 w-100" @click="open_map(detail.lat, detail.long)">
												<i class="fas fa-map-marker-alt"></i>
												Lihat Lokasi
											</button>
										</div>
										<div class="col-12">
											<p class="mt-2">{{ detail.address }}</p>
										</div>
									</div>
								</div>
							</div>
							<div class="col-12 col-lg-6 mb-3">
								<div class="custom-text-group h-100">
									<label>7.Masalah</label>
									<ul class="mb-0 ps-3">
										<li v-for="(problem, index) in problem_list" :key="index">{{ problem }}</li>
									</ul>
								</div>
							</div>
							<div class="col-12 col-lg-6 mb-3">
								<div class="custom-text-group h-100">
									<label>8.Rincian</label>
									<ul class="mb-0 ps-3">
										<li v-for="(service_detail, index) in service_detail_list" :key="index">{{ service_detail }}</li>
									</ul>
								</div>
							</div>
							<div class="col-12 mb-3">
								<div class="custom-text-group">
									<label>9.Keterangan</label>
									<p>{{ detail.note }}</p>
								</div>
							</div>
							<div class="col-12 mb-3">
								<div class="custom-text-group">
									<label>10.Foto Kondisi Awal</label>
									<div class="row">
										<template v-for="(photo, index) in detail.survey_photo" :key="index">
											<div class="col-12 col-lg-4 mb-3" v-if="photo.type == 'before'">
												<div class="custom-image-warpper shadow" v-viewer="{toolbar: false, navbar: false, keyboard: false}">
													<img :src="photo.photo_url" :alt="'before-survey-photo-' + (index+1)">
												</div>
											</div>
										</template>
									</div>
								</div>
							</div>
							<div class="col-12 mb-3" v-if="detail.status == 'already_done'">
								<div class="custom-text-group">
									<label>11.Foto Kondisi Akhir</label>
									<div class="row">
										<template v-for="(photo, index) in detail.survey_photo" :key="index">
											<div class="col-12 col-lg-4 mb-3" v-if="photo.type == 'after'">
												<div class="custom-image-warpper shadow" v-viewer="{toolbar: false, navbar: false, keyboard: false}">
													<img :src="photo.photo_url" :alt="'before-survey-photo-' + (index+1)">
												</div>
											</div>
										</template>
									</div>
								</div>
							</div>
							<div class="col-12 mt-4" v-if="detail.status == 'not_paid'">
								<h5 class="mb-3">Pembayaran</h5>
								<div class="mb-3" v-for="(payment, index) in detail.payments" :key="index">
									<div class="row align-items-center border-bottom mx-0">
										<div class="col-9 ps-0">
											<p>{{ payment.payment_type }}</p> 
										</div>
										<div class="col-3 pe-0">
											<button type="button" class="btn btn-sm custom-btn-02 mb-1 w-100" @click="check_payment(payment.payment_type_id, payment.trx_id)" v-if="payment.payment_type_id == 3">
												Check Status
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row justify-content-end">
						<div class="col-12 col-lg-3">
							<router-link :to="{name: 'survey', params: { status: status }}" class="btn custom-btn-outline-02 w-100">Kembali</router-link>
						</div>
					</div>
				</div>
			</div>
		</template>
	</content-component>
	<modal-component :modal_status="complete_modal" :modal_size="'modal-lg'">
		<template v-slot:modal_header>
			<div class="modal-title custom-font-lg custom-font-bold">Detail Survey</div>
			<button type="button" class="btn-close" @click="close_complete_modal()"/>
		</template>
		<template v-slot:modal_body>
			<div class="row">
				<div class="col-12 mb-3" :class="validation.firstError('form.photo') ? 'custom-form-error' : ''">
					<label>Foto Kondisi Akhir</label>
					<file-input
						:reference="'photo'"
						:multiple="true"
						:maximum="3"
						:max_size="1024000"
						:accept="'image/png, image/jpg, image/jpeg'"
						:extensions="['png', 'jpg', 'jpeg']"
						:min_compress="{width: 1000, height: 1000}"
						@set_queue="queue_files"
						:old_file="local_file"
						:old_delete="true"
					/>
					<div class="custom-form-error-message">{{ validation.firstError('form.photo') }}</div>
				</div>
			</div>
		</template>
		<template v-slot:modal_footer>
			<button type="button" class="btn custom-btn-outline-02" @click="close_complete_modal()">Tutup</button>
			<button type="button" class="btn custom-btn-02" @click="submit_complete()">Simpan</button>
		</template>
	</modal-component>
</template>

<script>
	import ContentComponent from '@/components/external/ContentComponent'
	import ModalComponent from '@/components/external/ModalComponent'
	import FileInput from '@/components/external/input/FileInput'
	import { mapGetters } from 'vuex'
	import { Validator } from 'simple-vue3-validator'
	import moment from 'moment'
	import Swal from 'sweetalert2'

	export default {
		components: {
			'content-component': ContentComponent,
			'modal-component': ModalComponent,
			'file-input': FileInput,
		},
		data() {
			return {
				status: this.$route.params.status,
				problem_list: [],
				service_detail_list: [],
				complete_modal: false,
				local_file: [],
				upload_count: 0,
			}
		},
		computed: {
			...mapGetters({
				detail: 'survey/getSurveyDetail',
				form: 'survey/getSurveyForm',
			}),
		},
		validators: {
			'form.photo': function(value){
				let parent = this
				return Validator.custom(function () {
					if (!Validator.isEmpty(value)) {
						for(var i = 0; i < value.length; i++) {
							if (value[i].error != '') {
								return 'Invalid file format'
							}
						}
					}
					if(parent.upload_count < 1){
						return 'Minimum 1 gambar.'
					}
					if(parent.upload_count > 3){
						return 'Maximum 3 gambar.'
					}
				})
			},
		},
		created(){
			this.init()
		},
		methods: {
			init(){
				this.$store.dispatch('survey/resetSurveyDetail')
				this.$store.dispatch('survey/detailSurvey', this.$route.params.id).then((response) => {
					if(response.status){
						if(response.data.status == 'not_paid'){
							this.get_problem(response.data.problem)
							this.get_service_detail(response.data.survey_detail)
						}
						else{
							this.problem_list = JSON.parse(response.data.problem)
							this.service_detail_list = JSON.parse(response.data.survey_detail)
						}
					}
				})
			},
			get_problem(value){
				this.$store.dispatch('survey/getProblemList', value).then((response) => {
					if(response.status){
						for(let i = 0; i < response.data.length; i++){
							this.problem_list.push(response.data[i].name)
						}
					}
				})
			},
			get_service_detail(value){
				this.$store.dispatch('survey/getServiceDetailList', value).then((response) => {
					if(response.status){
						for(let i = 0; i < response.data.length; i++){
							this.service_detail_list.push(response.data[i].name)
						}
					}
				})
			},
			date_format(value){
				return moment(value).format("DD-MM-YYYY")
			},
			hour_format(value){
				return moment(value).format("HH:mm")
			},
			open_map(lat, long){
				window.open(`https://maps.google.com/?q=${lat},${long}`)
			},
			check_payment(type_id, trx_id){
				let data = {
					type_id: type_id,
					trx_id: trx_id,
				}

				this.$store.dispatch('survey/getPaymentStatus', data).then((response) => {
					if(response.status){
						Swal.fire({
							icon: 'info',
							title: response.message,
							text: response.data,
							confirmButtonColor: this.$root.swal('confirmButtonColor'),
							confirmButtonText: this.$root.swal('doneButtonText'),
						})
					}
				})
			},
			update_paid(){
				let parent = this

				let data = {
					trx_id: parent.detail.payments[0].trx_id,
					payment_account: parent.detail.payments[0].payment_account,
				}

				Swal.fire({
					icon: 'warning',
					title: 'Apakah anda yakin ingin mengubah status data ini?',
					reverseButtons: true,
					showCancelButton: true,
					cancelButtonText: parent.$root.swal('cancelButtonText'),
					confirmButtonColor: parent.$root.swal('confirmButtonColor'),
					confirmButtonText: parent.$root.swal('confirmButtonText'),
				}).then((result) => {
					if (result.isConfirmed) {
						parent.$store.dispatch('survey/updateSurveyPaid', data).then((response) => {
							if(response.status){
								parent.$router.push({name: 'survey.detail', params: { status: 'paid', id: parent.$route.params.id }})
								Swal.fire({
									icon: 'success',
									title: 'Berhasil mengubah data',
									confirmButtonColor: parent.$root.swal('confirmButtonColor'),
									confirmButtonText: parent.$root.swal('doneButtonText'),
								})
							}
						})
					}
				})
			},
			update_progress(){
				let parent = this
				Swal.fire({
					icon: 'warning',
					title: 'Apakah anda yakin ingin mengubah status data ini?',
					reverseButtons: true,
					showCancelButton: true,
					cancelButtonText: parent.$root.swal('cancelButtonText'),
					confirmButtonColor: parent.$root.swal('confirmButtonColor'),
					confirmButtonText: parent.$root.swal('confirmButtonText'),
				}).then((result) => {
					if (result.isConfirmed) {
						parent.$store.dispatch('survey/updateSurveyProgress', parent.$route.params.id).then((response) => {
							if(response.status){
								parent.$router.push({name: 'survey.detail', params: { status: 'waiting_to_be_done', id: parent.$route.params.id }})
								Swal.fire({
									icon: 'success',
									title: 'Berhasil mengubah data',
									confirmButtonColor: parent.$root.swal('confirmButtonColor'),
									confirmButtonText: parent.$root.swal('doneButtonText'),
								})
							}
						})
					}
				})
			},
			open_complete_modal(value){
				if(value == 'create'){
					this.form.completed_at = moment().unix() * 1000
				}
				if(value == 'edit'){
					for (let i = 0; i < this.detail.survey_photo.length; ++i) {
						if(this.detail.survey_photo[i].type == 'after'){
							this.local_file.push({
								error: '',
								file_id: this.$root.copy(this.detail.survey_photo[i].id),
								file_status: this.$root.copy(this.detail.survey_photo[i].photo),
								file_name: this.$root.copy(this.detail.survey_photo[i].photo),
								file_url: this.$root.copy(this.detail.survey_photo[i].photo_url),
							})
						}
					}
					this.upload_count = this.local_file.length
					this.form.photo = this.local_file
				}
				this.complete_modal = true
			},
			close_complete_modal(){
				this.validation.reset()
				this.$store.dispatch('survey/resetSurveyForm')
				this.local_file = []
				this.upload_count = 0
				this.complete_modal = false
			},
			queue_files(data){
				if(data.files.length > 0){
					let files = []
					let count = 0
					let reserved_id = []
					for (let i = 0; i < data.files.length; ++i) {
						if(data.files[i].file){
							if(reserved_id.length){
								files.push({
									error: data.files[i].error,
									file_id: reserved_id[0],
									file: new File([data.files[i].file], data.files[i].file.name, {type: data.files[i].file.type})
								})
								
								files.splice(files.findIndex(x=> x.file_id === reserved_id[0]), 1)
								reserved_id.splice(0, 1)
							}
							else{
								files.push({
									error: data.files[i].error,
									file: new File([data.files[i].file], data.files[i].file.name, {type: data.files[i].file.type})
								})
							}

							count++
						}
						else{
							files.push({
								error: data.files[i].error,
								file_id: data.files[i].file_id,
								file_status: data.files[i].file_status,
								file_name: data.files[i].file_name,
								file_url: data.files[i].file_url
							})

							if(data.files[i].file_status == 'delete'){
								reserved_id.push(data.files[i].file_id)
							}
							else{
								count++
							}
						}
					}

					this.form[data.ref] = files
					this.upload_count = count
				}
				else{
					this.form[data.ref] = null
					this.upload_count = 0
				}
			},
			submit_complete(){
				let parent = this
				parent.$validate().then(function (success){
					if(success){
						let formData = new FormData();

						if(parent.form.completed_at){
							formData.append('completed_at', parent.form.completed_at)
						}

						for(let i= 0; i < parent.form.photo.length; i++) {
							if(parent.form.photo[i].file_id){
								if(parent.form.photo[i].file){
									formData.append(`photo[${i}][id]`, parent.form.photo[i].file_id)
									formData.append(`photo[${i}][file]`, parent.form.photo[i].file, parent.form.photo[i].file.name)
								}
								else{
									formData.append(`photo[${i}][id]`, parent.form.photo[i].file_id)
									formData.append(`photo[${i}][file]`, parent.form.photo[i].file_status)
								}
							}
							else{
								formData.append(`photo[${i}][file]`, parent.form.photo[i].file, parent.form.photo[i].file.name)
							}
						}

						let data = {
							id: parent.$route.params.id,
							data: formData
						}

						parent.$store.dispatch('survey/updateSurveyComplete', data).then((response) => {
							if(response.status){
								parent.close_complete_modal()
								if(parent.detail.status == 'waiting_to_be_done'){
									parent.$router.push({name: 'survey.detail', params: { status: 'already_done', id: parent.$route.params.id }})
								}
								if(parent.detail.status == 'already_done'){
									parent.init()
								}
								Swal.fire({
									icon: 'success',
									title: 'Berhasil mengubah data',
									confirmButtonColor: parent.$root.swal('confirmButtonColor'),
									confirmButtonText: parent.$root.swal('doneButtonText'),
								})
							}
						})
					}
					else{
						Swal.fire({
							icon: 'error',
							title: 'Error',
							text: parent.$root.swal('errorValidationText'),
							confirmButtonColor: parent.$root.swal('errorButtonColor'),
							confirmButtonText: parent.$root.swal('errorButtonText'),
						})
					}
				})
			},
		}
	}
</script>