<template>
    <div class="custom-text-editor">
        <ckeditor v-model="displayValue" :editor="editor" :config="editorConfig" :disabled="disabled" @ready="onReady"/>
    </div>
</template>

<script>
    import CKEditor from '@ckeditor/ckeditor5-vue';
    import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document'

	export default {
        props: ['value', 'placeholder', 'disabled'],
        components: {
            ckeditor: CKEditor.component
        },
        data() {
            return {
                editor: DecoupledEditor,
                editorConfig: {
                    language: 'en',
                    toolbar: {
                        items: [
                            'heading',
                            '|',
                            'fontSize',
                            'fontColor',
                            'fontBackgroundColor',
                            '|',
                            'bold',
                            'italic',
                            'underline',
                            'strikethrough',
                            '|',
                            'alignment',
                            '|',
                            'numberedList',
                            'bulletedList',
                            '|',
                            'indent',
                            'outdent',
                            '|',
                            'link',
                            'blockQuote',
                            'insertTable',
                            '|',
                            'undo',
                            'redo',
                        ]
                    },
                    table: {
                        contentToolbar: [
                            'tableColumn',
                            'tableRow',
                            'mergeTableCells'
                        ]
                    },
                    placeholder: this.placeholder ? this.placeholder : null,
                },
            }
        },
        computed: {
            displayValue: {
                get(){
                    if(this.value){
                        return this.value
                    }
                    else{
                        return ''
                    }
                },
                set(modifiedValue){
                    this.$emit('update:value', modifiedValue)
                }
            }
        },
        methods: {
            onReady(editor){
                editor.ui.getEditableElement().parentElement.insertBefore(
                    editor.ui.view.toolbar.element,
                    editor.ui.getEditableElement()
                );
            },
        }
	}
</script>