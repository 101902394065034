import Axios from 'axios'
import { constants } from '@/config/constant'

const tableSuggestion = async (context, payload) => {
    const url = constants.base_url + '/v1/suggestion/table'
    let response = await Axios.get(url, {headers:{'custom_loading': true}, params: payload})

    if(response.data.status){
        context.commit('SET_SUGGESTION_TABLE', response.data)
    }

    return response.data
}

const deleteSuggestion = async (context, payload) => {
    const url = constants.base_url + '/v1/suggestion/delete/' + payload
    let response = await Axios.delete(url)

    return response.data
}

export {
    tableSuggestion,
    deleteSuggestion,
}