const SET_BANNER = (state, payload) => {
    state.banner = payload.data
}

const RESET_BANNER_FORM = (state) => {
    state.banner_form = {
        name: null,
        link: null,
        image: null,
        is_published: null,
    }
}

export {
    SET_BANNER,
    RESET_BANNER_FORM,
}