<template>
    <modal-component :modal_status="modal">
        <template v-slot:modal_header>
            <div class="modal-title custom-font-lg custom-font-bold">{{ is_edit ? 'Ubah' : 'Tambah' }} Promo</div>
            <button type="button" class="btn-close" @click="close_modal(false)"/>
        </template>
        <template v-slot:modal_body>
            <div class="row">
                <div class="col-12 mb-3" :class="validation.firstError('form.image') ? 'custom-form-error' : ''">
                    <label>Gambar</label>
                    <file-input
                        :reference="'image'"
                        :multiple="true"
                        :maximum="1"
                        :max_size="2048000"
                        :accept="'image/png, image/jpg, image/jpeg'"
                        :extensions="['png', 'jpg', 'jpeg']"
                        :aspect_ratio="{width: 16, height: 9}"
                        :min_compress="{width: 1600, height: 900}"
                        :resize="{width: 1600, height: 900}"
                        @set_queue="queue_files"
                        :old_file="local_file"
                    />
                    <div class="custom-form-error-message">{{ validation.firstError('form.image') }}</div>
                </div>
                <div class="col-12 mb-3" :class="validation.firstError('form.name') ? 'custom-form-error' : ''">
                    <label class="form-label">Nama Promo</label>
                    <input type="text" class="form-control custom-form-control" placeholder="Nama Promo" v-model="form.name">
                    <div class="custom-form-error-message">{{ validation.firstError('form.name') }}</div>
                </div>
                <div class="col-12 mb-3" :class="validation.firstError('form.link') ? 'custom-form-error' : ''">
                    <label class="form-label">Tautan</label>
                    <input type="text" class="form-control custom-form-control" placeholder="Tautan" v-model="form.link">
                    <div class="custom-form-error-message">{{ validation.firstError('form.link') }}</div>
                </div>
                <div class="col-12 mb-3" :class="validation.firstError('form.is_published') ? 'custom-form-error' : ''">
                    <label class="form-label">Tampilkan</label>
                    <div class="d-flex align-items-center">
                        <div class="custom-radio me-2">
                            <input type="radio" id="iya" value="1" v-model="form.is_published">
                            <label for="iya">Iya</label>
                        </div>
                        <div class="custom-radio ms-2">
                            <input type="radio" id="tidak" value="0" v-model="form.is_published">
                            <label for="tidak">Tidak</label>
                        </div>
                    </div>
                    <div class="custom-form-error-message">{{ validation.firstError('form.is_published') }}</div>
                </div>
            </div>
        </template>
        <template v-slot:modal_footer>
            <button type="button" class="btn custom-btn-outline-02" @click="close_modal(false)">Tutup</button>
            <button type="button" class="btn custom-btn-02" @click="submit()">{{ is_edit ? 'Simpan' : 'Tambah' }}</button>
        </template>
    </modal-component>
</template>

<script>
    import ModalComponent from '@/components/external/ModalComponent'
    import FileInput from '@/components/external/input/FileInput'
    import { mapGetters } from 'vuex'
    import { Validator } from 'simple-vue3-validator'
    import Swal from 'sweetalert2'

	export default {
        props: ['published'],
        emits: ['close'],
        components: {
            'modal-component': ModalComponent,
            'file-input': FileInput,
        },
        data() {
            return {
                modal: false,
                is_edit: null,
                local_file: [],
            }
        },
        computed: {
            ...mapGetters({
                form: 'promotion/getPromotionForm',
            }),
        },
        validators: {
            'form.name': function(value){
                return Validator.value(value).required('Wajib di isi.')
            },
            'form.link': function(value){
                return Validator.value(value).required('Wajib di isi.').custom(function () {
                    if (!Validator.isEmpty(value)) {
                        try {
                            new URL(value)
                        } catch(error) {
                            return 'Invalid url format'
                        }
                    }
                })
            },
            'form.is_published': function(value){
                return Validator.value(value).required('Wajib di isi.')
            },
            'form.image': function(value){
                if(!this.is_edit){
                    return Validator.custom(function () {
                        if (!Validator.isEmpty(value)) {
                            for(var i = 0; i < value.length; i++) {
                                if (value[i].error != '') {
                                    return 'Invalid file format'
                                }
                            }
                        }
                        else {
                            return 'Wajib di isi.'
                        }
                    })
                }
            },
        },
        methods: {
            open_modal(value){
                if(value){
                    this.is_edit = value.id
                    this.form.name = value.name
                    this.form.link = value.link
                    this.form.is_published = value.is_published
                    this.local_file.push({
                        error: '',
                        file_status: this.$root.copy(value.image),
                        file_name: this.$root.copy(value.image),
                        file_url: this.$root.copy(value.image_url),
                    })
                }
                this.modal = true
            },
            close_modal(value){
                this.modal = false
                this.is_edit = null
                this.local_file = []
                this.validation.reset()
                this.$store.dispatch('promotion/resetPromotionForm')
                this.$emit('close', value)
            },
            queue_files(data){
                if(data.files.length > 0){
                    let files = []
                    for (let i = 0; i < data.files.length; ++i) {
                        if(data.files[i].file){
                            files.push({
                                error: data.files[i].error,
                                file: new File([data.files[i].file], data.files[i].file.name, {type: data.files[i].file.type})
                            })
                        }
                        else{
                            files.push({
                                error: data.files[i].error,
                                file_status: data.files[i].file_status,
                                file_name: data.files[i].file_name,
                                file_url: data.files[i].file_url
                            })
                        }
                    }
                    this.form[data.ref] = files
                }
                else{
                    this.form[data.ref] = null
                }
            },
            submit(){
                let parent = this
                // if(parent.published == 5 && parent.form.is_published == 1){
                //     Swal.fire({
                //         icon: 'error',
                //         title: 'Error',
                //         text: 'Maksimal 5 promo ditampilkan',
                //         confirmButtonColor: parent.$root.swal('errorButtonColor'),
                //         confirmButtonText: parent.$root.swal('errorButtonText'),
                //     })
                // }
                // else{
                    parent.$validate().then(function (success){
                        if(success){
                            let formData = new FormData();
                            formData.append('name', parent.form.name)
                            formData.append('link', parent.form.link)
                            formData.append('is_published', parent.form.is_published)

                            if(parent.form.image){
                                for(let i= 0; i < parent.form.image.length; i++) {
                                    if(parent.form.image[i].file){
                                        formData.append('image', parent.form.image[i].file, parent.form.image[i].file.name)
                                    }
                                    else{
                                        formData.append('image', parent.form.image[i].file_status)
                                    }
                                }
                            }

                            if(parent.is_edit){
                                let data = {
                                    id: parent.is_edit,
                                    data: formData
                                }
                                parent.$store.dispatch('promotion/updatePromotion', data).then((response) => {
                                    if(response.status){
                                        parent.close_modal(true)
                                        Swal.fire({
                                            icon: 'success',
                                            title: 'Berhasil mengubah data',
                                            confirmButtonColor: parent.$root.swal('confirmButtonColor'),
                                            confirmButtonText: parent.$root.swal('doneButtonText'),
                                        })
                                    }
                                })
                            }
                            else{
                                parent.$store.dispatch('promotion/createPromotion', formData).then((response) => {
                                    if(response.status){
                                        parent.close_modal(true)
                                        Swal.fire({
                                            icon: 'success',
                                            title: 'Berhasil menambah data',
                                            confirmButtonColor: parent.$root.swal('confirmButtonColor'),
                                            confirmButtonText: parent.$root.swal('doneButtonText'),
                                        })
                                    }
                                })
                            }
                        }
                        else{
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: parent.$root.swal('errorValidationText'),
                                confirmButtonColor: parent.$root.swal('errorButtonColor'),
                                confirmButtonText: parent.$root.swal('errorButtonText'),
                            })
                        }
                    })   
                // }
            },
        }
	}
</script>