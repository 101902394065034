import { createStore } from 'vuex'

//Modules
import auth from './auth';
import user from './user';
import survey from './survey';
import service from './service';
import banner from './master_data/banner';
import promotion from './master_data/promotion';
import show from './master_data/show';
import project from './master_data/project';
import article from './master_data/article';
import about from './master_data/about';
import faq from './master_data/faq';
import setting from './setup/setting';
import area from './setup/area';
import suggestion from './suggestion';
import notification from './notification';
import utilities from './utilities';

export default createStore({
	modules: {
		auth,
		user,
		survey,
		service,
		banner,
		promotion,
		show,
		project,
		article,
		about,
		faq,
		setting,
		area,
		suggestion,
		notification,
		utilities,
	}
})