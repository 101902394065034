const getPromotion = (state) => {
    return state.promotion
}

const getPromotionForm = (state) => {
    return state.promotion_form
}

export {
    getPromotion,
    getPromotionForm,
};