<template>
    <content-component>
        <template v-slot:content_body>
            <div class="custom-text-wrapper mb-4">
                <div class="row">
                    <div class="col-12 mb-3">
                        <div class="custom-font-lg border-bottom">Admin</div>
                    </div>
                    <div class="col-12 col-lg-6 mb-3" :class="validation.firstError('phone') ? 'custom-form-error' : ''">
                        <label class="form-label">Kontak Admin</label>
                        <div class="input-group">
                            <span class="input-group-text">+62</span>
                            <input type="text" class="form-control custom-form-control" placeholder="Kontak Admin" v-model="phone">
                        </div>
                        <div class="custom-form-error-message">{{ validation.firstError('phone') }}</div>
                    </div>
                    <div class="col-12 col-lg-6 mb-3" :class="validation.firstError('setting.survey_price') ? 'custom-form-error' : ''">
                        <label class="form-label">Harga Survey</label>
                        <currency-input v-model.lazy="setting.survey_price" :options="{valueRange: {min: 1000}}" placeholder="Harga Survey"/>
                        <div class="custom-form-error-message">{{ validation.firstError('setting.survey_price') }}</div>
                    </div>
                    <div class="col-12 mt-4 mb-3">
                        <div class="custom-font-lg border-bottom">Umum</div>
                    </div>
                    <div class="col-12 col-lg-6 mb-3">
                        <label class="form-label">Customer Service</label>
                        <div class="input-group">
                            <span class="input-group-text">+62</span>
                            <input type="text" class="form-control custom-form-control" placeholder="Customer Service" v-model="customer_service_phone">
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 mb-3">
                        <label class="form-label">Link Download</label>
                        <input type="text" class="form-control custom-form-control" placeholder="Link Download" v-model="setting.download_link">
                    </div>
                    <div class="col-12 col-lg-6 mb-3">
                        <label class="form-label">Link Youtube</label>
                        <input type="text" class="form-control custom-form-control" placeholder="Link Youtube" v-model="setting.youtube_link">
                    </div>
                    <div class="col-12 col-lg-6 mb-3">
                        <label class="form-label">Link Facebook</label>
                        <input type="text" class="form-control custom-form-control" placeholder="Link Facebook" v-model="setting.facebook_link">
                    </div>
                    <div class="col-12 col-lg-6 mb-3">
                        <label class="form-label">Link TikTok</label>
                        <input type="text" class="form-control custom-form-control" placeholder="Link TikTok" v-model="setting.tiktok_link">
                    </div>
                    <div class="col-12 col-lg-6 mb-3">
                        <label class="form-label">Link Instagram</label>
                        <input type="text" class="form-control custom-form-control" placeholder="Link Instagram" v-model="setting.instagram_link">
                    </div>
                    <div class="col-12 mb-3" :class="validation.firstError('setting.app_description') ? 'custom-form-error' : ''">
                        <label class="form-label">Deskripsi Aplikasi</label>
                        <text-editor v-model:value="setting.app_description" :placeholder="'Deskripsi Aplikasi'"/>
                        <div class="custom-form-error-message">{{ validation.firstError('setting.app_description') }}</div>
                    </div>
                    <div class="col-12 mb-3" :class="validation.firstError('setting.instruction') ? 'custom-form-error' : ''">
                        <label class="form-label">Petunjuk</label>
                        <text-editor v-model:value="setting.instruction" :placeholder="'Petunjuk'"/>
                        <div class="custom-form-error-message">{{ validation.firstError('setting.instruction') }}</div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-12 col-lg-3 order-lg-2">
                    <button type="button" class="btn custom-btn-02 w-100" @click="save()">Simpan</button>
                </div>
            </div>
        </template>
    </content-component>
</template>

<script>
	import ContentComponent from '@/components/external/ContentComponent'
    import CurrencyInput from '@/components/external/input/CurrencyInput'
    import TextEditor from '@/components/external/input/TextEditor'
    import { mapGetters } from 'vuex'
    import { Validator } from 'simple-vue3-validator'
    import Swal from 'sweetalert2'

	export default {
        components: {
            'content-component': ContentComponent,
            'currency-input': CurrencyInput,
            'text-editor': TextEditor,
        },
        data() {
            return {
                phone: null,
                customer_service_phone: null,
            }
        },
        computed: {
            ...mapGetters({
                setting: 'setting/getSetting',
            }),
        },
        validators: {
            'setting.survey_price': function(value){
                return Validator.value(value).required('Wajib di isi.')
            },
            'setting.app_description': function(value){
                return Validator.value(value).required('Wajib di isi.')
            },
            'phone': function(value){
                return Validator.value(value).required('Wajib di isi.').integer('Wajib angka')
            },
            'setting.instruction': function(value){
                return Validator.value(value).required('Wajib di isi.')
            },
            'customer_service_phone': function(value){
                return Validator.value(value).integer('Wajib angka')
            },
        },
        created(){
            this.init()
        },
        methods: {
            init(){
                this.$store.dispatch('setting/getSetting').then((response) => {
                    if(response.status){
                        this.phone = response.data.admin_phone ? response.data.admin_phone.substring(3) : null
                        this.customer_service_phone = response.data.customer_service ? response.data.customer_service.substring(3) : null
                    }
                })
            },
            save(){
                let parent = this
                parent.$validate().then(function (success){
                    if(success){
                        parent.setting.admin_phone = `+62${parent.phone}`
                        parent.setting.customer_service = parent.customer_service_phone ? `+62${parent.customer_service_phone}` : null

                        parent.$store.dispatch('setting/updateSetting', parent.setting).then((response) => {
                            if(response.status){
                                parent.init()
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Berhasil mengubah data',
                                    confirmButtonColor: parent.$root.swal('confirmButtonColor'),
                                    confirmButtonText: parent.$root.swal('doneButtonText'),
                                })
                            }
                        })
                    }
                    else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: parent.$root.swal('errorValidationText'),
                            confirmButtonColor: parent.$root.swal('errorButtonColor'),
                            confirmButtonText: parent.$root.swal('errorButtonText'),
                        })
                    }
                })
            },
        }
	}
</script>