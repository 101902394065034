const getDefaultState = () => {
	return {
		setting: {
			user_table: null,
		}
	}
}

const default_state = getDefaultState()

export { default_state }