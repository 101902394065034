<template>
    <modal-component :modal_status="modal">
        <template v-slot:modal_header>
            <div class="modal-question custom-font-lg custom-font-bold">{{ is_edit ? 'Ubah' : 'Tambah' }} Area</div>
            <button type="button" class="btn-close" @click="close_modal(false)"/>
        </template>
        <template v-slot:modal_body>
            <div class="row">
                <div class="col-12 mb-3" :class="validation.firstError('form.province_code') ? 'custom-form-error' : ''">
                    <label class="form-label">Provinsi</label>
                    <v-select v-model="form.province_code" :options="province_options" placeholder="Provinsi" label="label" :reduce="province_options => province_options.id" class="custom-vselect"/>
                    <div class="custom-form-error-message">{{ validation.firstError('form.province_code') }}</div>
                </div>
                <div class="col-12 mb-3" :class="validation.firstError('form.regency_code') ? 'custom-form-error' : ''">
                    <label class="form-label">Kabupaten</label>
                    <v-select v-model="form.regency_code" :options="regency_options" placeholder="Kabupaten" label="label" :reduce="regency_options => regency_options.id" :disabled="regency_options.length == 0" class="custom-vselect"/>
                    <div class="custom-form-error-message">{{ validation.firstError('form.regency_code') }}</div>
                </div>
            </div>
        </template>
        <template v-slot:modal_footer>
            <button type="button" class="btn custom-btn-outline-02" @click="close_modal(false)">Tutup</button>
            <button type="button" class="btn custom-btn-02" @click="submit()">{{ is_edit ? 'Simpan' : 'Tambah' }}</button>
        </template>
    </modal-component>
</template>

<script>
    import ModalComponent from '@/components/external/ModalComponent'
    import vSelect from 'vue-select'
    import { mapGetters } from 'vuex'
    import { Validator } from 'simple-vue3-validator'
    import Swal from 'sweetalert2'

	export default {
        emits: ['close'],
        components: {
            'modal-component': ModalComponent,
            'v-select': vSelect,
        },
        data() {
            return {
                modal: false,
                is_edit: null,
            }
        },
        computed: {
            ...mapGetters({
                form: 'area/getAreaForm',
                province_options: 'area/getProvinceOptions',
                regency_options: 'area/getRegencyOptions',
            }),
        },
        watch: {
            'form.province_code': function (newData, oldData) {
                if(newData){
                    this.$store.dispatch('area/getRegencyOptions', {province_code: this.form.province_code})
                    if(oldData && (newData != oldData)){
                        this.form.regency_code = null
                    }
                }
                else{
                    this.form.regency_code = null
                    this.$store.dispatch('area/resetRegencyOptions')
                }
            },
        },
        validators: {
            'form.province_code': function(value){
                return Validator.value(value).required('Wajib di isi.')
            },
            'form.regency_code': function(value){
                return Validator.value(value).required('Wajib di isi.')
            },
        },
        methods: {
            open_modal(value){
                this.$store.dispatch('area/getProvinceOptions')
                if(value){
                    this.$store.dispatch('area/getRegencyOptions', {province_code: value.province_code})
                    this.is_edit = value.id
                    this.form.province_code = value.province_code
                    this.form.regency_code = value.regency_code
                }
                this.modal = true
            },
            close_modal(value){
                this.modal = false
                this.is_edit = null
                this.validation.reset()
                this.$store.dispatch('area/resetAreaForm')
                this.$emit('close', value)
            },
            submit(){
                let parent = this
                parent.$validate().then(function (success){
                    if(success){
                        if(parent.is_edit){
                            let data = {
                                id: parent.is_edit,
                                data: parent.form
                            }
                            parent.$store.dispatch('area/updateArea', data).then((response) => {
                                if(response.status){
                                    parent.close_modal(true)
                                    Swal.fire({
                                        icon: 'success',
                                        title: 'Berhasil mengubah data',
                                        confirmButtonColor: parent.$root.swal('confirmButtonColor'),
                                        confirmButtonText: parent.$root.swal('doneButtonText'),
                                    })
                                }
                            })
                        }
                        else{
                            parent.$store.dispatch('area/createArea', parent.form).then((response) => {
                                if(response.status){
                                    parent.close_modal(true)
                                    Swal.fire({
                                        icon: 'success',
                                        title: 'Berhasil menambah data',
                                        confirmButtonColor: parent.$root.swal('confirmButtonColor'),
                                        confirmButtonText: parent.$root.swal('doneButtonText'),
                                    })
                                }
                            })
                        }
                    }
                    else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: parent.$root.swal('errorValidationText'),
                            confirmButtonColor: parent.$root.swal('errorButtonColor'),
                            confirmButtonText: parent.$root.swal('errorButtonText'),
                        })
                    }
                })  
            },
        }
	}
</script>