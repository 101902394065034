<template>
    <modal-component :modal_status="modal">
        <template v-slot:modal_header>
            <div class="modal-title custom-font-lg custom-font-bold">Detail Pengguna</div>
            <button type="button" class="btn-close" @click="close_modal()"/>
        </template>
        <template v-slot:modal_body>
            <div class="row">
                <div class="col-12">
                    <div class="d-flex justify-content-center mb-3">
                        <div class="custom-avatar-lg-warpper" v-viewer="{toolbar: false, navbar: false, keyboard: false}">
                            <img :src="detail.photo_url" alt="customer-photo">
                        </div>
                    </div>
                    <div class="custom-text-group">
                        <label>Name</label>
                        <p>{{ detail.name }}</p>
                    </div>
                    <div class="custom-text-group">
                        <label>Email</label>
                        <p>{{ detail.email }}</p>
                    </div>
                    <div class="custom-text-group">
                        <label>Telepon</label>
                        <p>{{ detail.phone }}</p>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:modal_footer>
            <button type="button" class="btn custom-btn-outline-02" @click="close_modal()">Tutup</button>
        </template>
    </modal-component>
</template>

<script>
    import ModalComponent from '@/components/external/ModalComponent'

	export default {
        components: {
            'modal-component': ModalComponent,
        },
        data() {
            return {
                modal: false,
                detail: null,
            }
        },
        methods: {
            open_modal(value){
                this.detail = value
                this.modal = true
            },
            close_modal(){
                this.modal = false
                this.detail = null
            },
        }
	}
</script>