<template>
    <content-component>
        <template v-slot:table_create_button>
            <button class="btn custom-btn-02 w-100" @click="open_form_modal()">
                <i class="fas fa-plus"></i>
                Promo
            </button>
        </template>
        <template v-slot:content_body>
            <div class="row g-3" v-if="data.length">
                <div v-for="(promotion, index) in data" :key="index" class="col-12 col-lg-4">
                    <div class="rounded shadow-sm">
                        <div class="custom-image-warpper position-relative" v-viewer="{toolbar: false, navbar: false, keyboard: false}">
                            <div class="custom-published" v-if="promotion.is_published == 1"><i class="fas fa-check"></i></div>
                            <img :src="promotion.image_url" alt="promotion-image">
                        </div>
                        <div class="d-flex justify-content-between custom-upload-preview-button">
                            {{ promotion.name }}
                            <div class="dropdown">
                                <button class="btn custom-btn-unborder-02 py-0" type="button" :id="`dropdownPromotion${promotion.id}`" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="fas fa-ellipsis-v"></i>
                                </button>
                                <ul class="dropdown-menu" :aria-labelledby="`dropdownPromotion${promotion.id}`">
                                    <li><span class="dropdown-item" @click="open_form_modal(promotion)">Ubah</span></li>
                                    <li><span class="dropdown-item" @click="delete_data(promotion.id)">Hapus</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="w-100 p-3 text-center">No Data.</div>
            </div>
        </template>
    </content-component>
    <promotion-form-modal ref="promotionFormModal" :published="published" @close="close_modal"/>
</template>

<script>
	import ContentComponent from '@/components/external/ContentComponent'
    import PromotionFormModal from './PromotionFormModal'
    import { mapGetters } from 'vuex'
    import Swal from 'sweetalert2'

	export default {
        components: {
            'content-component': ContentComponent,
            'promotion-form-modal': PromotionFormModal,
        },
        data() {
            return {
                published: 0,
            }
        },
        computed: {
            ...mapGetters({
                data: 'promotion/getPromotion',
            }),
        },
        created(){
            this.init()
        },
        methods: {
            init(){
                this.$store.dispatch('promotion/getPromotion').then((response) => {
                    if(response.status){
                        this.published = 0
                        for(let i = 0; i < response.data.length; i++){
                            if(response.data[i].is_published){
                                this.published++
                            }
                        }
                    }
                })
            },
            open_form_modal(value){
                this.$refs.promotionFormModal.open_modal(value)
            },
            close_modal(value){
                if(value){
                    this.init()
                }
            },
            delete_data(id){
                let parent = this
                Swal.fire({
                    icon: 'warning',
                    title: 'Apakah anda yakin ingin menghapus data ini?',
                    reverseButtons: true,
                    showCancelButton: true,
                    cancelButtonText: parent.$root.swal('cancelButtonText'),
                    confirmButtonColor: parent.$root.swal('errorButtonColor'),
                    confirmButtonText: parent.$root.swal('deleteButtonText'),
                }).then((result) => {
                    if (result.isConfirmed) {
                        parent.$store.dispatch('promotion/deletePromotion', id).then((response) => {
                            if(response.status){
                                parent.init()
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Berhasil menghapus data',
                                    confirmButtonColor: parent.$root.swal('confirmButtonColor'),
                                    confirmButtonText: parent.$root.swal('doneButtonText'),
                                })
                            }
                        })
                    }
                })
            },
        }
	}
</script>

<style scoped>
    .custom-published {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        width: 23px;
        height: 23px;
        background-color: rgba(243, 124, 0, 1);
        color: rgba(255, 255, 255, 1);
        text-align: center;
        border-radius: 23px;
    }
</style>