const getDefaultState = () => {
	return {
		setting: {
			survey_price: null,
			app_description: null,
			admin_phone: null,
			instruction: null,
			customer_service: null,
			download_link: null,
			youtube_link: null,
			facebook_link: null,
			tiktok_link: null,
			instagram_link: null,
		}
	}
}

const default_state = getDefaultState()

export { default_state }