import Axios from 'axios'
import { constants } from '@/config/constant'

const getSetting = async (context) => {
    const url = constants.base_url + '/v1/setting/get'
    let response = await Axios.get(url)

    if(response.data.status){
        context.commit('SET_SETTING', response.data.data)
    }

    return response.data
}

const updateSetting = async (context, payload) => {
    const url = constants.base_url + '/v1/setting/update'
    let response = await Axios.patch(url, payload)

    return response.data
}

export {
    getSetting,
    updateSetting,
}