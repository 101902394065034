<template>
	<!-- Tabs wrapper -->
    <div class="custom-tabs-wrapper mb-4">
        <button type="button" class="btn px-4" :class="tabs_index == index ? 'custom-tabs-btn active' : 'custom-tabs-btn'" v-for="(tab, index) in tabs_attributes" :key="index" @click="change_tabs(index)">
            {{ tab.label }}
        </button>
    </div>
    <div class="dropdown d-block d-lg-none mb-4">
        <button class="btn d-flex justify-content-between custom-tabs-btn active" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            {{ tabs_attributes[tabs_index].label }}
            <i class="fas fa-angle-down"></i>
        </button>
        <ul class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
            <li v-for="(tab, index) in tabs_attributes" :key="index">
                <div class="dropdown-item" @click="change_tabs(index)">{{ tab.label }}</div>
            </li>
        </ul>
    </div>
</template>

<script>
	export default {
        props: ['tabs_index', 'tabs_attributes'],
        emits: ['change'],
        methods: {
			change_tabs(value){
				this.$emit('change', value)
			},
        }
	}
</script>