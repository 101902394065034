const getDefaultState = () => {
	return {
		service: [],
		service_favorite: [],
		service_table: null,
		service_detail: null,
		service_form: {
			name: null,
			icon: null,
		},
		problem_form: {
			service_id: null,
			name: null,
		},
		service_detail_form: {
			service_id: null,
			name: null,
		},
		service_price_form: {
			service_id: null,
			product: null,
			unit: null,
			price: null,
			image: null,
		},
	}
}

const default_state = getDefaultState()

export { default_state }