const SET_FAQ_TABLE = (state, payload) => {
    state.faq_table = payload.data
}

const RESET_FAQ_FORM = (state) => {
    state.faq_form = {
        question: null,
        answer: null,
    }
}

export {
    SET_FAQ_TABLE,
    RESET_FAQ_FORM,
}