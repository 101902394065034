<template>
    <modal-component :modal_status="modal">
        <template v-slot:modal_header>
            <div class="modal-title custom-font-lg custom-font-bold">{{ is_edit ? 'Ubah' : 'Tambah' }} Detail</div>
            <button type="button" class="btn-close" @click="close_modal(false)"/>
        </template>
        <template v-slot:modal_body>
            <div class="row">
                <div class="col-12 mb-3" :class="validation.firstError('form.name') ? 'custom-form-error' : ''">
                    <label class="form-label">Detail Layanan</label>
                    <input type="text" class="form-control custom-form-control" placeholder="Detail Layanan" v-model="form.name">
                    <div class="custom-form-error-message">{{ validation.firstError('form.name') }}</div>
                </div>
            </div>
        </template>
        <template v-slot:modal_footer>
            <button type="button" class="btn custom-btn-outline-02" @click="close_modal(false)">Tutup</button>
            <button type="button" class="btn custom-btn-02" @click="submit()">{{ is_edit ? 'Simpan' : 'Tambah' }}</button>
        </template>
    </modal-component>
</template>

<script>
    import ModalComponent from '@/components/external/ModalComponent'
    import { mapGetters } from 'vuex'
    import { Validator } from 'simple-vue3-validator'
    import Swal from 'sweetalert2'

	export default {
        emits: ['close'],
        components: {
            'modal-component': ModalComponent,
        },
        data() {
            return {
                modal: false,
                is_edit: null,
            }
        },
        validators: {
            'form.name': function(value){
                return Validator.value(value).required('Wajib di isi.')
            },
        },
        computed: {
            ...mapGetters({
                form: 'service/getServiceDetailForm',
            }),
        },
        methods: {
            open_modal(service_id, value){
                if(value){
                    this.is_edit = value.id
                    this.form.name = value.name
                }
                this.form.service_id = service_id
                this.modal = true
            },
            close_modal(value){
                this.modal = false
                this.is_edit = null
                this.validation.reset()
                this.$store.dispatch('service/resetServiceDetailForm')
                this.$emit('close', value)
            },
            submit(){
                let parent = this
                parent.$validate().then(function (success){
                    if(success){
                        if(parent.is_edit){
                            let data = {
                                id: parent.is_edit,
                                data: parent.form
                            }
                            parent.$store.dispatch('service/updateServiceDetail', data).then((response) => {
                                if(response.status){
                                    parent.close_modal(true)
                                    Swal.fire({
                                        icon: 'success',
                                        title: 'Berhasil mengubah data',
                                        confirmButtonColor: parent.$root.swal('confirmButtonColor'),
                                        confirmButtonText: parent.$root.swal('doneButtonText'),
                                    })
                                }
                            })
                        }
                        else{
                            parent.$store.dispatch('service/createServiceDetail', parent.form).then((response) => {
                                if(response.status){
                                    parent.close_modal(true)
                                    Swal.fire({
                                        icon: 'success',
                                        title: 'Berhasil menambah data',
                                        confirmButtonColor: parent.$root.swal('confirmButtonColor'),
                                        confirmButtonText: parent.$root.swal('doneButtonText'),
                                    })
                                }
                            })
                        }
                    }
                    else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: parent.$root.swal('errorValidationText'),
                            confirmButtonColor: parent.$root.swal('errorButtonColor'),
                            confirmButtonText: parent.$root.swal('errorButtonText'),
                        })
                    }
                })
            },
		}
	}
</script>