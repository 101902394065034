import Axios from 'axios'
import { constants } from '@/config/constant'

const getBanner = async (context) => {
    const url = constants.base_url + '/v1/banner/all'
    let response = await Axios.get(url)

    if(response.data.status){
        context.commit('SET_BANNER', response.data)
    }

    return response.data
}

const createBanner = async (context, payload) => {
    const url = constants.base_url + '/v1/banner/create'
    let response = await Axios.post(url, payload, {headers:{'Content-Type': 'multipart/form-data'}})

    return response.data
}

const updateBanner = async (context, payload) => {
    const url = constants.base_url + '/v1/banner/update/' + payload.id
    let response = await Axios.patch(url, payload.data)

    return response.data
}

const deleteBanner = async (context, payload) => {
    const url = constants.base_url + '/v1/banner/delete/' + payload
    let response = await Axios.delete(url)

    return response.data
}

const resetBannerForm = async (context) => {
    context.commit('RESET_BANNER_FORM')
}

export {
    getBanner,
    createBanner,
    updateBanner,
    deleteBanner,
    resetBannerForm,
}