const isLoading = async (context, payload) => {
    context.commit('SET_LOADING', payload)
}

const isRefresh = async (context) => {
    context.commit('SET_REFRESH')
}

const isRefreshToken = async (context, payload) => {
    context.commit('SET_REFRESH_TOKEN', payload)
}

export {
    isLoading,
    isRefresh,
    isRefreshToken,
}