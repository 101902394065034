const SET_PROJECT = (state, payload) => {
    state.project = payload.data
}

const RESET_PROJECT_FORM = (state) => {
    state.project_form = {
        title: null,
        address: null,
        image: null,
        is_published: null,
    }
}

export {
    SET_PROJECT,
    RESET_PROJECT_FORM,
}