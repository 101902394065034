const getBanner = (state) => {
    return state.banner
}

const getBannerForm = (state) => {
    return state.banner_form
}

export {
    getBanner,
    getBannerForm,
};