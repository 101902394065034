import Axios from 'axios'
import crypto from 'crypto-js'
import { constants } from '@/config/constant'

const checkAuth = async (context) => {
    const hasUser = localStorage.getItem('user')
    const hasToken = localStorage.getItem('token')
    const hasRefreshToken = localStorage.getItem('refresh_token')

    if(hasUser && hasToken && hasRefreshToken){
        context.commit('SET_USER', JSON.parse(crypto.AES.decrypt(localStorage.getItem('user'), constants.crypto_key).toString(crypto.enc.Utf8)))
    
        return true
    }
    else{
        localStorage.removeItem('token')
        localStorage.removeItem('refresh_token')
        localStorage.removeItem('user')

        return false
    }
}

const signIn = async (context, payload) => {
    const url = constants.base_url + '/v1/login'
    let response = await Axios.post(url, payload)

    if(response.data.status){
        localStorage.setItem('token', crypto.AES.encrypt(response.data.data.access_token, constants.crypto_key).toString())
        localStorage.setItem('refresh_token', crypto.AES.encrypt(response.data.data.refresh_token, constants.crypto_key).toString())
        localStorage.setItem('user', crypto.AES.encrypt(JSON.stringify(response.data.data.user), constants.crypto_key).toString())
        context.commit('SET_USER', JSON.parse(crypto.AES.decrypt(localStorage.getItem('user'), constants.crypto_key).toString(crypto.enc.Utf8)))
    }
    
    return response.data
}

const signOut = async (context) => {
    const url = constants.base_url + '/v1/logout'
    let response = await Axios.post(url)
    
    localStorage.removeItem('token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('user')
    context.commit('RESET_USER')

    return response
}

const changePassword = async (context, payload) => {
    const url = constants.base_url + '/v1/user/update_password'
    let response = await Axios.patch(url, payload)
    
    return response.data
}

const resetSignInForm = async (context) => {
	context.commit('RESET_SIGN_IN_FORM')
}

const resetChangePasswordForm = async (context) => {
	context.commit('RESET_CHANGE_PASSWORD_FORM')
}

export {
    checkAuth,
    signIn,
    signOut,
    changePassword,
    resetSignInForm,
    resetChangePasswordForm,
}