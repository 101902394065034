const SET_SHOW = (state, payload) => {
    state.show = payload.data
}

const RESET_SHOW_FORM = (state) => {
    state.show_form = {
        name: null,
        link: null,
        image: null,
        is_published: null,
    }
}

export {
    SET_SHOW,
    RESET_SHOW_FORM,
}