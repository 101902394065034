const getDefaultState = () => {
	return {
		article: [],
		article_form: {
			title: null,
			description: null,
			image: null,
			link: null,
			is_published: null,
		}
	}
}

const default_state = getDefaultState()

export { default_state }