import Axios from 'axios'
import { constants } from '@/config/constant'

const getAbout = async (context) => {
    const url = constants.base_url + '/v1/about/all'
    let response = await Axios.get(url)

    if(response.data.status){
        context.commit('SET_ABOUT', response.data)
    }

    return response.data
}

const createAbout = async (context, payload) => {
    const url = constants.base_url + '/v1/about/create'
    let response = await Axios.post(url, payload, {headers:{'Content-Type': 'multipart/form-data'}})

    return response.data
}

const updateAbout = async (context, payload) => {
    const url = constants.base_url + '/v1/about/update/' + payload.id
    let response = await Axios.post(url, payload.data, {headers:{'Content-Type': 'multipart/form-data'}})

    return response.data
}

const deleteAbout = async (context, payload) => {
    const url = constants.base_url + '/v1/about/delete/' + payload
    let response = await Axios.delete(url)

    return response.data
}

const resetAboutForm = async (context) => {
    context.commit('RESET_ABOUT_FORM')
}

export {
    getAbout,
    createAbout,
    updateAbout,
    deleteAbout,
    resetAboutForm,
}