const isLoading = (state) => {
    return state.loading
}

const isRefresh = (state) => {
    return state.refresh_count
}

const isRefreshToken = (state) => {
    return state.refresh_token
}

export {
    isLoading,
    isRefresh,
    isRefreshToken,
};