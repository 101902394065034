import Dashboard from "@/components/admin/dashboard/DashboardComponent";
import User from "@/components/admin/user/UserComponent";
import Survey from "@/components/admin/survey/SurveyComponent";
import SurveyDetail from "@/components/admin/survey/SurveyDetailComponent";
import Service from "@/components/admin/service/ServiceComponent";
import ServiceDetail from "@/components/admin/service/ServiceDetailComponent";
// import Banner from "@/components/admin/master_data/banner/BannerComponent";
import Promotion from "@/components/admin/master_data/promotion/PromotionComponent";
import Show from "@/components/admin/master_data/show/ShowComponent";
import Project from "@/components/admin/master_data/project/ProjectComponent";
import Article from "@/components/admin/master_data/article/ArticleComponent";
import About from "@/components/admin/master_data/about/AboutComponent";
import FaQ from "@/components/admin/master_data/faq/FAQComponent";
import Setting from "@/components/admin/setup/setting/SettingComponent";
import Area from "@/components/admin/setup/area/AreaComponent";
import Suggestion from "@/components/admin/suggestion/SuggestionComponent";

export default [
	// Dashboard
	{
		path : '/dashboard',
		name : 'dashboard',
		meta : { layout: "admin", parent: 'dashboard', sidebar:'dashboard', breadcrumbs: 'Dashboard', title: 'Dashboard', auth: true },
		component : Dashboard
	},
	// User
	{
		path : '/user',
		name : 'user',
		meta : { layout: "admin", parent: 'user', sidebar:'user', breadcrumbs: 'Pengguna', title: 'Data Pengguna', auth: true },
		component : User
	},
	// Survey
	{
		path : '/survey/:status',
		name : 'survey',
		meta : { layout: "admin", parent: 'survey', sidebar:'survey', breadcrumbs: 'Survey', title: 'Data Survey', auth: true },
		component : Survey
	},
	{
		path : '/survey/:status/detail/:id',
		name : 'survey.detail',
		meta : { layout: 'admin', parent: 'survey', sidebar:'survey', breadcrumbs: 'Detail.Survey', title: 'Detail Survey', auth: true },
		component : SurveyDetail
	},
	// Service
	{
		path : '/service',
		name : 'service',
		meta : { layout: "admin", parent: 'service', sidebar:'service', breadcrumbs: 'Data.Layanan', title: 'Data Layanan', auth: true },
		component : Service
	},
	{
		path : '/service/detail/:id',
		name : 'service.detail',
		meta : { layout: "admin", parent: 'service', sidebar:'service', breadcrumbs: 'Detail.Layanan', title: 'Detail Layanan', auth: true },
		component : ServiceDetail
	},
	// Master Data
	// Banner
	// {
	// 	path : '/master_data/banner',
	// 	name : 'master_data.banner',
	// 	meta : { layout: "admin", parent: 'master_data', sidebar:'master_data.banner', breadcrumbs: 'Data Dasar.Banner', title: 'Data Banner', auth: true },
	// 	component : Banner
	// },
	// Promotion
	{
		path : '/master_data/promotion',
		name : 'master_data.promotion',
		meta : { layout: "admin", parent: 'master_data', sidebar:'master_data.promotion', breadcrumbs: 'Data Dasar.Promo', title: 'Data Promo', auth: true },
		component : Promotion
	},
	// Show
	{
		path : '/master_data/show',
		name : 'master_data.show',
		meta : { layout: "admin", parent: 'master_data', sidebar:'master_data.show', breadcrumbs: 'Data Dasar.Tontonan', title: 'Data Tontonan', auth: true },
		component : Show
	},
	// Project
	{
		path : '/master_data/project',
		name : 'master_data.project',
		meta : { layout: "admin", parent: 'master_data', sidebar:'master_data.project', breadcrumbs: 'Data Dasar.Project', title: 'Data Project', auth: true },
		component : Project
	},
	// Article
	{
		path : '/master_data/article',
		name : 'master_data.article',
		meta : { layout: "admin", parent: 'master_data', sidebar:'master_data.article', breadcrumbs: 'Data Dasar.Artikel', title: 'Data Artikel', auth: true },
		component : Article
	},
	// About
	{
		path : '/master_data/about',
		name : 'master_data.about',
		meta : { layout: "admin", parent: 'master_data', sidebar:'master_data.about', breadcrumbs: 'Data Dasar.Tentang Kami', title: 'Data Tentang Kami', auth: true },
		component : About
	},
	// FAQ
	{
		path : '/master_data/faq',
		name : 'master_data.faq',
		meta : { layout: "admin", parent: 'master_data', sidebar:'master_data.faq', breadcrumbs: 'Data Dasar.FAQ', title: 'Data FAQ', auth: true },
		component : FaQ
	},
	// Setup
	// Setting
	{
		path : '/setup/setting',
		name : 'setup.setting',
		meta : { layout: "admin", parent: 'setup', sidebar:'setup.setting', breadcrumbs: 'Pengaturan.Aplikasi', title: 'Pengaturan Aplikasi', auth: true },
		component : Setting
	},
	// Area
	{
		path : '/setup/area',
		name : 'setup.area',
		meta : { layout: "admin", parent: 'setup', sidebar:'setup.area', breadcrumbs: 'Pengaturan.Wilayah', title: 'Pengaturan Wilayah', auth: true },
		component : Area
	},
	// Suggestion
	{
		path : '/suggestion',
		name : 'suggestion',
		meta : { layout: "admin", parent: 'suggestion', sidebar:'suggestion', breadcrumbs: 'Kritik & Saran', title: 'Kritik & saran', auth: true },
		component : Suggestion
	},
];