const getSurveyTable = (state) => {
    return state.survey_table
}

const getSurveyDetail = (state) => {
    return state.survey_detail
}

const getSurveyForm = (state) => {
    return state.survey_form
}

export {
    getSurveyTable,
    getSurveyDetail,
    getSurveyForm,
};