import Axios from 'axios'
import { constants } from '@/config/constant'

const getService = async (context) => {
    const url = constants.base_url + '/v1/service/all'
    let response = await Axios.get(url)

    if(response.data.status){
        context.commit('SET_SERVICE', response.data)
    }

    return response.data
}

const getServiceFavorite = async (context) => {
    const url = constants.base_url + '/v1/service/favorite'
    let response = await Axios.get(url)

    if(response.data.status){
        context.commit('SET_SERVICE_FAVORITE', response.data)
    }

    return response.data
}


const tableService = async (context, payload) => {
    const url = constants.base_url + '/v1/service/table'
    let response = await Axios.get(url, {headers:{'custom_loading': true}, params: payload})

    if(response.data.status){
        context.commit('SET_SERVICE_TABLE', response.data)
    }

    return response.data
}

const detailService = async (context, payload) => {
    const url = constants.base_url + '/v1/service/detail/' + payload
    let response = await Axios.get(url)

    if(response.data.status){
        context.commit('SET_SERVICE_DETAIL', response.data)
    }

    return response.data
}

const createService = async (context, payload) => {
    const url = constants.base_url + '/v1/service/create'
    let response = await Axios.post(url, payload, {headers:{'Content-Type': 'multipart/form-data'}})

    return response.data
}

const updateService = async (context, payload) => {
    const url = constants.base_url + '/v1/service/update/' + payload.id
    let response = await Axios.post(url, payload.data, {headers:{'Content-Type': 'multipart/form-data'}})

    return response.data
}

const updateServiceRank = async (context, payload) => {
    const url = constants.base_url + '/v1/service/update/ranks'
    let response = await Axios.patch(url, payload)

    return response.data
}

const deleteService = async (context, payload) => {
    const url = constants.base_url + '/v1/service/delete/' + payload
    let response = await Axios.delete(url)

    return response.data
}

const createProblem = async (context, payload) => {
    const url = constants.base_url + '/v1/problem/create'
    let response = await Axios.post(url, payload)

    return response.data
}

const updateProblem = async (context, payload) => {
    const url = constants.base_url + '/v1/problem/update/' + payload.id
    let response = await Axios.patch(url, payload.data)

    return response.data
}

const deleteProblem = async (context, payload) => {
    const url = constants.base_url + '/v1/problem/delete/' + payload
    let response = await Axios.delete(url)

    return response.data
}

const createServiceDetail = async (context, payload) => {
    const url = constants.base_url + '/v1/service_detail/create'
    let response = await Axios.post(url, payload)

    return response.data
}

const updateServiceDetail = async (context, payload) => {
    const url = constants.base_url + '/v1/service_detail/update/' + payload.id
    let response = await Axios.patch(url, payload.data)

    return response.data
}

const deleteServiceDetail = async (context, payload) => {
    const url = constants.base_url + '/v1/service_detail/delete/' + payload
    let response = await Axios.delete(url)

    return response.data
}

const createServicePrice = async (context, payload) => {
    const url = constants.base_url + '/v1/service_price/create'
    let response = await Axios.post(url, payload, {headers:{'Content-Type': 'multipart/form-data'}})

    return response.data
}

const updateServicePrice = async (context, payload) => {
    const url = constants.base_url + '/v1/service_price/update/' + payload.id
    let response = await Axios.post(url, payload.data, {headers:{'Content-Type': 'multipart/form-data'}})

    return response.data
}

const deleteServicePrice = async (context, payload) => {
    const url = constants.base_url + '/v1/service_price/delete/' + payload
    let response = await Axios.delete(url)

    return response.data
}

const resetServiceDetail = async (context) => {
    context.commit('RESET_SERVICE_DETAIL')
}

const resetServiceForm = async (context) => {
    context.commit('RESET_SERVICE_FORM')
}

const resetProblemForm = async (context) => {
    context.commit('RESET_PROBLEM_FORM')
}

const resetServiceDetailForm = async (context) => {
    context.commit('RESET_SERVICE_DETAIL_FORM')
}

const resetServicePriceForm = async (context) => {
    context.commit('RESET_SERVICE_PRICE_FORM')
}

export {
    getService,
    getServiceFavorite,
    tableService,
    detailService,
    createService,
    updateService,
    updateServiceRank,
    deleteService,
    createProblem,
    updateProblem,
    deleteProblem,
    createServiceDetail,
    updateServiceDetail,
    deleteServiceDetail,
    createServicePrice,
    updateServicePrice,
    deleteServicePrice,
    resetProblemForm,
    resetServiceForm,
    resetServiceDetail,
    resetServiceDetailForm,
    resetServicePriceForm,
}