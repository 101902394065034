import Axios from 'axios'
import { constants } from '@/config/constant'

const getProject = async (context) => {
    const url = constants.base_url + '/v1/project/all'
    let response = await Axios.get(url)

    if(response.data.status){
        context.commit('SET_PROJECT', response.data)
    }

    return response.data
}

const createProject = async (context, payload) => {
    const url = constants.base_url + '/v1/project/create'
    let response = await Axios.post(url, payload, {headers:{'Content-Type': 'multipart/form-data'}})

    return response.data
}

const updateProject = async (context, payload) => {
    const url = constants.base_url + '/v1/project/update/' + payload.id
    let response = await Axios.post(url, payload.data, {headers:{'Content-Type': 'multipart/form-data'}})

    return response.data
}

const deleteProject = async (context, payload) => {
    const url = constants.base_url + '/v1/project/delete/' + payload
    let response = await Axios.delete(url)

    return response.data
}

const resetProjectForm = async (context) => {
    context.commit('RESET_PROJECT_FORM')
}

export {
    getProject,
    createProject,
    updateProject,
    deleteProject,
    resetProjectForm,
}