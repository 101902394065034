<template>
    <content-component>
        <template v-slot:content_body>
            <div class="row" v-if="detail">
                <div class="col-12">
                    <div class="custom-text-wrapper mb-4">
                        <div class="row align-items-center">
                            <div class="col-12 col-lg-2 mb-2 mb-lg-4 d-flex justify-content-center justify-content-lg-start">
                                <div class="custom-icon-warpper shadow-sm" v-viewer="{toolbar: false, navbar: false, keyboard: false}">
                                    <img :src="detail.icon_url" alt="service-icon">
                                </div>
                            </div>
                            <div class="col-12 col-lg-10 mb-4 text-center text-lg-start">
                                <div class="custom-font-lg custom-font-bold">{{ detail.name }}</div>
                            </div>
                            <div class="col-12 mb-4">
                                <div class="custom-text-wrapper">
                                    <div class="d-flex justify-content-between align-items-center mb-3">
                                        <div class="custom-font-lg">Masalah</div>
                                        <button class="btn custom-btn-02" style="width: 120px;" @click="open_problem_form_modal()">
                                            <i class="fas fa-plus"></i>
                                            Masalah
                                        </button>
                                    </div>
                                    <div class="mb-3" v-if="detail.problem.length">
                                        <div class="row mb-2" v-for="(data, index) in detail.problem" :key="index">
                                            <div class="col-12 col-lg-10">
                                                <div class="custom-text-group">
                                                    <label>Nama Masalah</label>
                                                    <p>{{ data.name }}</p>
                                                </div>
                                            </div>
                                            <div class="col-12 col-lg-2">
                                                <div class="d-flex justify-content-around align-items-center h-100">
                                                    <button type="button" class="btn custom-btn-outline-02 me-2 mt-3 mt-lg-0 w-100" @click="open_problem_form_modal(data)">
                                                        <i class="fas fa-pen"></i>
                                                    </button>
                                                    <button type="button" class="btn custom-btn-outline-02 ms-2 mt-3 mt-lg-0 w-100" @click="delete_problem_data(data.id)">
                                                        <i class="fas fa-trash"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3" v-else>
                                        <div class="text-center my-2">No Data.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mb-4">
                                <div class="custom-text-wrapper">
                                    <div class="d-flex justify-content-between align-items-center mb-3">
                                        <div class="custom-font-lg">Detail</div>
                                        <button class="btn custom-btn-02" style="width: 120px;" @click="open_detail_form_modal()">
                                            <i class="fas fa-plus"></i>
                                            Detail
                                        </button>
                                    </div>
                                    <div class="mb-3" v-if="detail.service_detail.length">
                                        <div class="row mb-2" v-for="(data, index) in detail.service_detail" :key="index">
                                            <div class="col-12 col-lg-10">
                                                <div class="custom-text-group">
                                                    <label>Nama Detail</label>
                                                    <p>{{ data.name }}</p>
                                                </div>
                                            </div>
                                            <div class="col-12 col-lg-2">
                                                <div class="d-flex justify-content-around align-items-center h-100">
                                                    <button type="button" class="btn custom-btn-outline-02 me-2 mt-3 mt-lg-0 w-100" @click="open_detail_form_modal(data)">
                                                        <i class="fas fa-pen"></i>
                                                    </button>
                                                    <button type="button" class="btn custom-btn-outline-02 ms-2 mt-3 mt-lg-0 w-100" @click="delete_detail_data(data.id)">
                                                        <i class="fas fa-trash"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3" v-else>
                                        <div class="text-center my-2">No Data.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mb-4">
                                <div class="custom-text-wrapper">
                                    <div class="d-flex justify-content-between align-items-center mb-3">
                                        <div class="custom-font-lg">Produk</div>
                                        <button class="btn custom-btn-02" style="width: 120px;" @click="open_price_form_modal()">
                                            <i class="fas fa-plus"></i>
                                            Produk
                                        </button>
                                    </div>
                                    <div class="mb-3" v-if="detail.service_price.length">
                                        <div class="row g-3">
                                            <div v-for="(data, index) in detail.service_price" :key="index" class="col-12 col-lg-3">
                                                <card-component :header_class="'p-2'" :body_class="'p-0'">
                                                    <template v-slot:card_header>
                                                        <div class="d-flex justify-content-between">
                                                            <strong>{{ data.product }}</strong>
                                                            <div class="dropdown">
                                                                <button class="btn custom-btn-unborder-02 py-0" type="button" :id="`dropdownAbout${data.id}`" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <i class="fas fa-ellipsis-v"></i>
                                                                </button>
                                                                <ul class="dropdown-menu" :aria-labelledby="`dropdownAbout${data.id}`">
                                                                    <li><span class="dropdown-item" @click="open_price_form_modal(data)">Ubah</span></li>
                                                                    <li><span class="dropdown-item" @click="delete_price_data(data.id)">Hapus</span></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <template v-slot:card_body>
                                                        <div class="custom-thumbnail border-bottom" v-viewer="{toolbar: false, navbar: false, keyboard: false}">
                                                            <img :src="data.image_url" alt="service-price-image">
                                                        </div>
                                                        <div class="p-2">
                                                            <div>{{ currency_format(data.price) }}</div>
                                                            <div class="custom-font-color-secondary custom-font-sm">{{ data.unit }}</div>
                                                        </div>
                                                    </template>
                                                </card-component>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3" v-else>
                                        <div class="text-center my-2">No Data.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-end">
                        <div class="col-12 col-lg-3">
                            <router-link :to="{name: 'service'}" class="btn custom-btn-outline-02 w-100">Kembali</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </content-component>
    <service-problem-form-modal ref="serviceProblemFormModal" @close="close_modal"/>
    <service-detail-form-modal ref="serviceDetailFormModal" @close="close_modal"/>
    <service-price-form-modal ref="servicePriceFormModal" @close="close_modal"/>
</template>

<script>
    import ContentComponent from '@/components/external/ContentComponent'
    import CardComponent from '@/components/external/CardComponent'
    import ServiceProblemFormModal from './ServiceProblemFormModal'
    import ServiceDetailFormModal from './ServiceDetailFormModal'
    import ServicePriceFormModal from './ServicePriceFormModal'
    import { mapGetters } from 'vuex'
    import Swal from 'sweetalert2'

	export default {
        components: {
            'content-component': ContentComponent,
            'card-component': CardComponent,
            'service-problem-form-modal': ServiceProblemFormModal,
            'service-detail-form-modal': ServiceDetailFormModal,
            'service-price-form-modal': ServicePriceFormModal,
        },
        computed: {
            ...mapGetters({
                detail: 'service/getServiceDetail',
            }),
        },
        created(){
            this.init()
        },
        methods: {
            init(){
                this.$store.dispatch('service/resetServiceDetail')
                this.$store.dispatch('service/detailService', this.$route.params.id)
            },
            currency_format(value){
                return this.$root.currency(value)
            },
            open_problem_form_modal(value){
                this.$refs.serviceProblemFormModal.open_modal(this.detail.id, value)
            },
            open_detail_form_modal(value){
                this.$refs.serviceDetailFormModal.open_modal(this.detail.id, value)
            },
            open_price_form_modal(value){
                this.$refs.servicePriceFormModal.open_modal(this.detail.id, value)
            },
            close_modal(value){
                if(value){
                    this.$store.dispatch('service/detailService', this.detail.id)
                }
            },
            delete_problem_data(id){
                let parent = this
                Swal.fire({
                    icon: 'warning',
                    title: 'Apakah anda yakin ingin menghapus data ini?',
                    reverseButtons: true,
                    showCancelButton: true,
                    cancelButtonText: parent.$root.swal('cancelButtonText'),
                    confirmButtonColor: parent.$root.swal('errorButtonColor'),
                    confirmButtonText: parent.$root.swal('deleteButtonText'),
                }).then((result) => {
                    if (result.isConfirmed) {
                        parent.$store.dispatch('service/deleteProblem', id).then((response) => {
                            if(response.status){
                                parent.$store.dispatch('service/detailService', parent.detail.id)
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Berhasil menghapus data',
                                    confirmButtonColor: parent.$root.swal('confirmButtonColor'),
                                    confirmButtonText: parent.$root.swal('doneButtonText'),
                                })
                            }
                        })
                    }
                })
            },
            delete_detail_data(id){
                let parent = this
                Swal.fire({
                    icon: 'warning',
                    title: 'Apakah anda yakin ingin menghapus data ini?',
                    reverseButtons: true,
                    showCancelButton: true,
                    cancelButtonText: parent.$root.swal('cancelButtonText'),
                    confirmButtonColor: parent.$root.swal('errorButtonColor'),
                    confirmButtonText: parent.$root.swal('deleteButtonText'),
                }).then((result) => {
                    if (result.isConfirmed) {
                        parent.$store.dispatch('service/deleteServiceDetail', id).then((response) => {
                            if(response.status){
                                parent.$store.dispatch('service/detailService', parent.detail.id)
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Berhasil menghapus data',
                                    confirmButtonColor: parent.$root.swal('confirmButtonColor'),
                                    confirmButtonText: parent.$root.swal('doneButtonText'),
                                })
                            }
                        })
                    }
                })
            },
            delete_price_data(id){
                let parent = this
                Swal.fire({
                    icon: 'warning',
                    title: 'Apakah anda yakin ingin menghapus data ini?',
                    reverseButtons: true,
                    showCancelButton: true,
                    cancelButtonText: parent.$root.swal('cancelButtonText'),
                    confirmButtonColor: parent.$root.swal('errorButtonColor'),
                    confirmButtonText: parent.$root.swal('deleteButtonText'),
                }).then((result) => {
                    if (result.isConfirmed) {
                        parent.$store.dispatch('service/deleteServicePrice', id).then((response) => {
                            if(response.status){
                                parent.$store.dispatch('service/detailService', parent.detail.id)
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Berhasil menghapus data',
                                    confirmButtonColor: parent.$root.swal('confirmButtonColor'),
                                    confirmButtonText: parent.$root.swal('doneButtonText'),
                                })
                            }
                        })
                    }
                })
            },
		}
	}
</script>