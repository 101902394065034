<template>
	<!-- Sidebar -->
	<div class="custom-sidebar-wrapper" v-if="user">
		<div class="custom-sidebar-group">
			<!-- Dashboard -->
			<router-link :to="{ name: 'dashboard' }" :class="sidebar == 'dashboard' ? 'custom-sidebar-menu active': 'custom-sidebar-menu'" @click="auto_close()">
				<i class="nav-icon fas fa-tachometer-alt"></i>
				<p>Dashboard</p>
			</router-link>
			<!-- User -->
			<router-link :to="{ name: 'user' }" :class="sidebar == 'user' ? 'custom-sidebar-menu active': 'custom-sidebar-menu'" @click="auto_close()">
				<i class="fas fa-user"></i>
				<p>Pengguna</p>
			</router-link>
			<!-- Survey -->
			<router-link :to="{name: 'survey', params: { status: 'not_paid' }}" :class="sidebar == 'survey' ? 'custom-sidebar-menu active': 'custom-sidebar-menu'" @click="auto_close()">
				<i class="fas fa-clipboard"></i>
				<p>Survey</p>
			</router-link>
			<!-- Service -->
			<router-link :to="{name: 'service'}" :class="sidebar == 'service' ? 'custom-sidebar-menu active': 'custom-sidebar-menu'" @click="auto_close()">
				<i class="fas fa-cubes"></i>
				<p>Layanan</p>
			</router-link>
			<!-- Master Data -->
			<button class="custom-sidebar-menu" :class="parent == 'master_data' ? '' : 'collapsed'" type="button" data-bs-toggle="collapse" data-bs-target="#collapseMasterData" :aria-expanded="parent == 'master_data' ? true : false">
				<i class="fas fa-database"></i>
				<div class="custom-sidebar-collapse">
					<p>Data Dasar</p>
					<i class="fas fa-angle-up"></i>
				</div>
			</button>
			<div class="collapse" :class="parent == 'master_data' ? 'show' : ''"  id="collapseMasterData">
				<!-- <router-link :to="{name: 'master_data.banner'}" :class="sidebar == 'master_data.banner' ? 'custom-sidebar-menu active': 'custom-sidebar-menu'" @click="auto_close()">
					<i class="nav-icon far fa-circle"></i>
					<p>Data Banner</p>
				</router-link> -->
				<router-link :to="{name: 'master_data.promotion'}" :class="sidebar == 'master_data.promotion' ? 'custom-sidebar-menu active': 'custom-sidebar-menu'" @click="auto_close()">
					<i class="nav-icon far fa-circle"></i>
					<p>Data Promo</p>
				</router-link>
				<router-link :to="{name: 'master_data.show'}" :class="sidebar == 'master_data.show' ? 'custom-sidebar-menu active': 'custom-sidebar-menu'" @click="auto_close()">
					<i class="nav-icon far fa-circle"></i>
					<p>Data Tontonan</p>
				</router-link>
				<router-link :to="{name: 'master_data.project'}" :class="sidebar == 'master_data.project' ? 'custom-sidebar-menu active': 'custom-sidebar-menu'" @click="auto_close()">
					<i class="nav-icon far fa-circle"></i>
					<p>Data Project</p>
				</router-link>
				<router-link :to="{name: 'master_data.article'}" :class="sidebar == 'master_data.article' ? 'custom-sidebar-menu active': 'custom-sidebar-menu'" @click="auto_close()">
					<i class="nav-icon far fa-circle"></i>
					<p>Data Artikel</p>
				</router-link>
				<router-link :to="{name: 'master_data.about'}" :class="sidebar == 'master_data.about' ? 'custom-sidebar-menu active': 'custom-sidebar-menu'" @click="auto_close()">
					<i class="nav-icon far fa-circle"></i>
					<p>Data Tentang Kami</p>
				</router-link>
				<router-link :to="{name: 'master_data.faq'}" :class="sidebar == 'master_data.faq' ? 'custom-sidebar-menu active': 'custom-sidebar-menu'" @click="auto_close()">
					<i class="nav-icon far fa-circle"></i>
					<p>Data FAQ</p>
				</router-link>
			</div>
			<!-- Setting -->
			<button class="custom-sidebar-menu" :class="parent == 'setup' ? '' : 'collapsed'" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSetup" :aria-expanded="parent == 'setup' ? true : false">
				<i class="fas fa-wrench"></i>
				<div class="custom-sidebar-collapse">
					<p>Pengaturan</p>
					<i class="fas fa-angle-up"></i>
				</div>
			</button>
			<div class="collapse" :class="parent == 'setup' ? 'show' : ''"  id="collapseSetup">
				<router-link :to="{name: 'setup.setting'}" :class="sidebar == 'setup.setting' ? 'custom-sidebar-menu active': 'custom-sidebar-menu'" @click="auto_close()">
					<i class="nav-icon far fa-circle"></i>
					<p>Aplikasi</p>
				</router-link>
				<router-link :to="{name: 'setup.area'}" :class="sidebar == 'setup.area' ? 'custom-sidebar-menu active': 'custom-sidebar-menu'" @click="auto_close()">
					<i class="nav-icon far fa-circle"></i>
					<p>Wilayah</p>
				</router-link>
			</div>
			<!-- Suggestion -->
			<router-link :to="{ name: 'suggestion' }" :class="sidebar == 'suggestion' ? 'custom-sidebar-menu active': 'custom-sidebar-menu'" @click="auto_close()">
				<i class="fas fa-envelope-open-text"></i>
				<p>Kritik & Saran</p>
			</router-link>
		</div>
		<div class="custom-sidebar-footer">
			PHI © {{ current_year }}.
		</div>
	</div>
</template>

<script>
	import moment from 'moment';

	export default {
		props: ['user'],
		emits: ['clicked'],
		data() {
			return {
				current_year: moment().year(),
			}
		},
		computed: {
			parent() {
				return this.$route.meta.parent
			},
			sidebar() {
				return this.$route.meta.sidebar
			},
		},
		methods: {
			auto_close(){
				if(window.innerWidth < 992){
					this.$emit('clicked', true)
				}
			}
		}
	}
</script>