const getAreaTable = (state) => {
    return state.area_table
}

const getAreaForm = (state) => {
    return state.area_form
}

const getProvinceOptions = (state) => {
    return state.province_options
}

const getRegencyOptions = (state) => {
    return state.regency_options
}

const getDistrictOptions = (state) => {
    return state.district_options
}

export {
    getAreaTable,
    getAreaForm,
    getProvinceOptions,
    getRegencyOptions,
    getDistrictOptions,
};