<template>
    <div class="custom-login-wrapper d-flex justify-content-center align-items-center">
        <div class="custom-login-card">
            <card-component>
                <template v-slot:card_body>
                    <form @submit.prevent="signIn()">
                        <div class="text-center mb-3">
                            <img src="@/assets/image/logo-2.png" style="width: auto; height: 100px;">
                        </div>
                        <div class="custom-login-header">Sign In</div>
                        <div class="mb-3" :class="validation.firstError('form.email') ? 'custom-form-error' : ''">
                            <label class="form-label">Email</label>
                            <div class="input-group">
                                <input type="email" class="form-control custom-form-control" placeholder="Email" v-model="form.email">
                            </div>
                            <div class="custom-form-error-message">{{ validation.firstError('form.email') }}</div>
                        </div>
                        <div class="mb-3" :class="validation.firstError('form.password') ? 'custom-form-error' : ''">
                            <label class="form-label">Password</label>
                            <div class="input-group">
                                <input :type="show_password ? 'text' : 'password'" class="form-control custom-form-control" placeholder="Password" autocomplete="off" v-model="form.password">
                                <div class="input-group-append">
                                    <button type="button" class="btn custom-form-control-append-button" @click="password_toggle()"><i class="fas fa-eye"></i></button>
                                </div>
                            </div>
                            <div class="custom-form-error-message">{{ validation.firstError('form.password') }}</div>
                        </div>
                        <button type="submit" class="btn custom-btn-02 w-100 mt-3">Sign In</button>
                    </form>
                </template>
            </card-component>
        </div>
    </div>
</template>

<script>
    import CardComponent from '@/components/external/CardComponent'
    import { mapGetters } from 'vuex'
    import { Validator } from 'simple-vue3-validator'
    import Swal from 'sweetalert2'

	export default {
        components: {
            'card-component': CardComponent
        },
        data() {
            return {
                show_password: false,
            }
        },
        computed: {
            ...mapGetters({
                user: 'auth/getUser',
                form: 'auth/getSignInForm',
            }),
        },
        validators: {
            'form.email': function(value){
                return Validator.value(value).required('Email is required.').email('Email is invalid.')
            },
            'form.password': function(value){
                return Validator.value(value).required('Password is required.')
            }
        },
        created(){
            this.$store.dispatch('auth/resetSignInForm')
            this.$store.dispatch('auth/checkAuth').then((response) => {
                if(response){
                    this.$router.push({name: 'dashboard'})
                }
            })
        },
        methods: {
            password_toggle(){
                this.show_password = !this.show_password
            },
            signIn(){
                let parent = this
                parent.$validate().then(function (success){
                    if(success){
                        parent.$store.dispatch('auth/signIn', parent.form).then((response) => {
                            if(response.status){
                                if(parent.user.role_id == 1){
                                    parent.$router.push({name: 'dashboard'})
                                }
                                else{
                                    parent.$store.dispatch('auth/signOut')
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Error',
                                        text: 'User Not Found.',
                                        confirmButtonColor: parent.$root.swal('errorButtonColor'),
                                        confirmButtonText: parent.$root.swal('errorButtonText'),
                                    })
                                }
                            }
                        })
                    }
                })
            },
        },
	}
</script>