<template>
	<!-- Content -->
    <content-component>
        <template v-slot:content_body>
            <tabs-component :tabs_index="tabs_index" :tabs_attributes="tabs_attributes" @change="change_tabs"></tabs-component>
            <component :is="tabs_attributes[tabs_index].component"></component>
        </template>
    </content-component>
</template>

<script>
    import ContentComponent from '@/components/external/ContentComponent'
    import TabsComponent from '@/components/external/TabsComponent'
    import NewSurvey from './SurveyNewComponent'
    import PaidSurvey from './SurveyPaidComponent'
    import ProgressSurvey from './SurveyProgressComponent'
    import CompleteSurvey from './SurveyCompleteComponent'

	export default {
        components: {
            'content-component': ContentComponent,
            'tabs-component': TabsComponent,
            NewSurvey,
            PaidSurvey,
            ProgressSurvey,
            CompleteSurvey,
        },
        data() {
            return {
                tabs_index: 0,
                tabs_attributes: [
                    {
                        name: 'not_paid',
                        label: 'Belum Dibayar',
                        component: 'NewSurvey'
                    },
                    {
                        name: 'paid',
                        label: 'Sudah Dibayar',
                        component: 'PaidSurvey'
                    },
                    {
                        name: 'waiting_to_be_done',
                        label: 'Dalam Pengerjaan',
                        component: 'ProgressSurvey'
                    },
                    {
                        name: 'already_done',
                        label: 'Selesai',
                        component: 'CompleteSurvey'
                    }
                ],
            }
        },
        watch:{
            '$route.params.status': {
				deep: true,
				handler: function(newData){
                    if(this.$route.name == 'survey'){
                        this.tabs_index = this.tabs_attributes.findIndex(x => x.name === newData)
                        this.$router.push({name: 'survey', params: { status: this.tabs_attributes[this.tabs_index ].name }})
                    }
				}
			},
        },
        created(){
            this.init()
        },
        methods: {
            init(){
                this.tabs_index = this.tabs_attributes.findIndex(x => x.name === this.$route.params.status)
            },
			change_tabs(value){
                this.tabs_index = value
                this.$router.push({name: 'survey', params: { status: this.tabs_attributes[this.tabs_index].name }})
			},
        }
	}
</script>