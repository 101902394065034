const getAbout = (state) => {
    return state.about
}

const getAboutForm = (state) => {
    return state.about_form
}

export {
    getAbout,
    getAboutForm,
};