const getDefaultState = () => {
	return {
		loading: false,
		refresh_count: 0,
		refresh_token: false,
	}
}

const default_state = getDefaultState()

export { default_state }