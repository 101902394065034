<template>
	<table-component :attributes="table_attributes" :table_header="table_header" :table="table" @get_table="get_table">
		<template v-slot:table_button>
			<div class="row">
				<div class="col-12 col-lg-7">
					<date-range-input placeholder="Filter Tanggal" @change="set_date_filter"/>
				</div>
				<div class="col-12 col-lg-3">
					<button type="button" class="btn custom-btn-02 w-100" @click="filter()">
						Filter
					</button>
				</div>
			</div>
		</template>
		<template v-slot:table_body>
			<tr v-for="(data, index) in table_data" :key="index">
				<td>{{ date_format(data.created_date) + ' / ' + hour_format(data.created_date) }}</td>
				<td>{{ date_format(data.appointment_date) + ' / ' + hour_format(data.appointment_date) }}</td>
				<td>{{ data.service }}</td>
				<td>{{ data.name }}</td>
				<td>
                    <div class="custom-alert w-100 text-center" :class="data.datetime_expired == 0 ? 'alert-warning' : 'alert-success'">{{ data.datetime_expired == 0  ? 'Tunai': 'Transfer' }}</div>
                </td>
				<td class="text-center">
					<div class="d-flex justify-content-around">
						<button type="button" class="btn custom-btn-unborder-02 py-0" @click="open_whatsapp(data.phone)">
							<i class="fab fa-whatsapp"></i>
						</button>
						<router-link :to="{ name : 'survey.detail', params: { id: data.id }}" class="btn custom-btn-unborder-02 py-0">
                            <i class="fas fa-eye"></i>
                        </router-link>
						<button type="button" class="btn custom-btn-unborder-02 py-0" @click="delete_data(data.id)">
                            <i class="fas fa-trash"></i>
                        </button>
					</div>
				</td>
			</tr>
		</template>
	</table-component>
</template>

<script>
	import TableComponent from '@/components/external/TableComponent'
	import DateRangeInput from '@/components/external/input/date/DateRangeInput'
	import { mapGetters } from 'vuex'
	import moment from 'moment'
	import Swal from 'sweetalert2'

	export default {
		components: {
			'table-component': TableComponent,
			'date-range-input': DateRangeInput,
		},
		data() {
			return {
				table_header: [
					{ name: 'created_at', title: 'Tanggal Pesan', width: '17%', sort: true },
					{ name: 'date', title: 'Tanggal Survey', width: '17%', sort: true },
					{ name: 'service', title: 'Layanan', width: '21%', sort: true },
					{ name: 'name', title: 'Nama', width: '21%', sort: true },
					{ name: 'payment_type', title: 'Pembayaran', width: '14%', sort: true },
					{ name: '', title: '', width: '10%', sort: false },
				],
				table_attributes: {
					sort_by: 'created_at',
					sort_type: 'desc',
					search: null,
					page: 1,
					filter_by: null,
					filter: null,
				},
				table_data: [],
			}
		},
		computed: {
			...mapGetters({
				table: 'survey/getSurveyTable',
			}),
		},
		created(){
			this.get_table(this.table_attributes)
		},
		methods: {
			get_table(value){
				value.status = 'already_done'
				this.$store.dispatch('survey/tableSurvey', value).then((response) => {
					if(response.status){
						if(value.page == 1){
							this.table_data = this.table.data
						}
						else{
							this.table_data = [ ...this.table_data, ...this.table.data]
						}
						this.table_attributes = value
					}
				})
			},
			set_date_filter(value){
				if(value.length){
					this.table_attributes.filter_by = 'date'
					this.table_attributes.filter = value
				}
				else{
					this.table_attributes.filter_by = null
					this.table_attributes.filter = null
					this.filter()
				}
			},
			filter(){
				this.table_attributes.page = 1
				this.get_table(this.table_attributes)
			},
			date_format(value){
				return moment(value).format("DD-MM-YYYY")
			},
			hour_format(value){
				return moment(value).format("HH:mm")
			},
			open_whatsapp(value){
				let phone = value.charAt(0) == '0' ? '62' + value.substring(1) : value
				window.open(`https://wa.me/${phone}`)
			},
			delete_data(id){
                let parent = this
                Swal.fire({
                    icon: 'warning',
                    title: 'Apakah anda yakin ingin menghapus data ini?',
                    reverseButtons: true,
                    showCancelButton: true,
                    cancelButtonText: parent.$root.swal('cancelButtonText'),
                    confirmButtonColor: parent.$root.swal('errorButtonColor'),
                    confirmButtonText: parent.$root.swal('deleteButtonText'),
                }).then((result) => {
                    if (result.isConfirmed) {
                        parent.$store.dispatch('survey/deleteSurvey', id).then((response) => {
                            if(response.status){
                                parent.table_attributes.page = 1
                                parent.get_table(parent.table_attributes)
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Berhasil menghapus data',
                                    confirmButtonColor: parent.$root.swal('confirmButtonColor'),
                                    confirmButtonText: parent.$root.swal('doneButtonText'),
                                })
                            }
                        })
                    }
                })
            },
		}
	}
</script>