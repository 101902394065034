const SET_SURVEY_TABLE = (state, payload) => {
    state.survey_table = payload.data
}

const SET_SURVEY_DETAIL = (state, payload) => {
    state.survey_detail = payload.data
}

const RESET_SURVEY_DETAIL = (state) => {
    state.survey_detail = null
}

const RESET_SURVEY_FORM = (state) => {
    state.survey_form = {
        photo: null,
        completed_at: null,
    }
}

export {
    SET_SURVEY_TABLE,
    SET_SURVEY_DETAIL,
    RESET_SURVEY_DETAIL,
    RESET_SURVEY_FORM,
}