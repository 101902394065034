<template>
    <content-component>
        <template v-slot:content_body>
            <table-component :attributes="table_attributes" :table_header="table_header" :table="table" @get_table="get_table">
                <template v-slot:table_body>
                    <tr v-for="(data, index) in table_data" :key="index">
                        <td>{{ data.formatted_id }}</td>
                        <td>{{ data.name }}</td>
                        <td>{{ data.email }}</td>
                        <td>{{ data.phone }}</td>
                        <td class="text-center">
                            <div class="d-flex justify-content-around">
                                <button type="button" class="btn custom-btn-unborder-02 py-0" @click="open_detail_modal(data)">
                                    <i class="fas fa-eye"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </template>
            </table-component>
        </template>
    </content-component>
    <user-detail-modal ref="userDetailModal"/>
</template>

<script>
	import ContentComponent from '@/components/external/ContentComponent'
    import TableComponent from '@/components/external/TableComponent'
    import UserDetailModal from './UserDetailModal'
    import { mapGetters } from 'vuex'

	export default {
        components: {
            'content-component': ContentComponent,
            'table-component': TableComponent,
            'user-detail-modal': UserDetailModal,
        },
        data() {
            return {
                table_header: [
                    { name: 'id', title: 'ID', width: '10%', sort: true },
                    { name: 'name', title: 'Nama', width: '30%', sort: true },
                    { name: 'email', title: 'Email', width: '30%', sort: true },
                    { name: 'phone', title: 'Telepon', width: '20%', sort: true },
                    { name: '', title: '', width: '10%', sort: false },
                ],
                table_attributes: {
                    sort_by: 'id',
                    sort_type: 'asc',
                    search: null,
                    page: 1,
                },
                table_data: [],
            }
        },
        computed: {
            ...mapGetters({
                table: 'user/getUserTable',
            }),
        },
        created(){
            this.get_table(this.table_attributes)
        },
        methods: {
            get_table(value){
                this.$store.dispatch('user/tableUser', value).then((response) => {
                    if(response.status){
                        if(value.page == 1){
                            this.table_data = this.table.data
                        }
                        else{
                            this.table_data = [ ...this.table_data, ...this.table.data]
                        }
                        this.table_attributes = value
                    }
                })
            },
            open_detail_modal(value){
                this.$refs.userDetailModal.open_modal(value)
            },
        }
	}
</script>