<template>
	<component :is="layout">
		<router-view :key="$route.fullPath+refresh_count"/>
	</component>
</template>

<script>
	import { mapGetters } from 'vuex'

	export default {
		computed: {
			...mapGetters({
                refresh_count: 'utilities/isRefresh',
            }),
			layout() {
				return this.$route.meta.layout + '-layout'
			}
		}
	}
</script>