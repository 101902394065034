import Axios from 'axios'
import { constants } from '@/config/constant'

const getPromotion = async (context) => {
    const url = constants.base_url + '/v1/promotion/all'
    let response = await Axios.get(url)

    if(response.data.status){
        context.commit('SET_PROMOTION', response.data)
    }

    return response.data
}

const createPromotion = async (context, payload) => {
    const url = constants.base_url + '/v1/promotion/create'
    let response = await Axios.post(url, payload, {headers:{'Content-Type': 'multipart/form-data'}})

    return response.data
}

const updatePromotion = async (context, payload) => {
    const url = constants.base_url + '/v1/promotion/update/' + payload.id
    let response = await Axios.post(url, payload.data, {headers:{'Content-Type': 'multipart/form-data'}})

    return response.data
}

const deletePromotion = async (context, payload) => {
    const url = constants.base_url + '/v1/promotion/delete/' + payload
    let response = await Axios.delete(url)

    return response.data
}

const resetPromotionForm = async (context) => {
    context.commit('RESET_PROMOTION_FORM')
}

export {
    getPromotion,
    createPromotion,
    updatePromotion,
    deletePromotion,
    resetPromotionForm,
}