<template>
    <Datepicker
        v-model="date"
        :format="format"
        :placeholder="placeholder"
        range
        autoApply
        :closeOnAutoApply="false"
        :enableTimePicker="false"
        class="custom-date-input"
        @update:modelValue="select_date"
    />
</template>

<script>
    import Datepicker from '@vuepic/vue-datepicker'
    import '@vuepic/vue-datepicker/dist/main.css'
    import moment from 'moment'
    
    export default {
        components: { Datepicker },
        props: ['placeholder'],
        emits: ['change'],
        data() {
            return {
                date: [],
            };
        },
        methods: {
            format(){
                if(this.date){
                    let start = moment(this.date[0]).format("DD/MM/YYYY")
                    let end = moment(this.date[1]).format("DD/MM/YYYY")

                    return start == end ? `${start}` : `${start} - ${end}`
                }
            },
			select_date(){
                let value = []

                if(this.date){
                    value[0] = moment(this.date[0]).unix() * 1000
                    value[1] = moment(this.date[1]).unix() * 1000
                }

                this.$emit('change', value)
			},
        }
    }
</script>