const getDefaultState = () => {
	return {
		banner: [],
		banner_form: {
			name: null,
			link: null,
			image: null,
			is_published: null,
		}
	}
}

const default_state = getDefaultState()

export { default_state }