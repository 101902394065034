<template>
    <content-component>
        <template v-slot:content_body>
            <table-component :attributes="table_attributes" :table_header="table_header" :table="table" @get_table="get_table">
                <template v-slot:table_body>
                    <tr v-for="(data, index) in table_data" :key="index">
                        <td class="align-top">{{ data.name }}</td>
                        <td class="align-top">
                            <div v-html="data.description"></div>
                        </td>
                        <td class="align-top">{{ date_format(data.date) }}</td>
                        <td class="text-center">
                            <div class="d-flex justify-content-around">
                                <button type="button" class="btn custom-btn-unborder-02 py-0" @click="delete_data(data.id)">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </template>
            </table-component>
        </template>
    </content-component>
</template>

<script>
	import ContentComponent from '@/components/external/ContentComponent'
    import TableComponent from '@/components/external/TableComponent'
    import { mapGetters } from 'vuex'
    import Swal from 'sweetalert2'
    import moment from 'moment'

	export default {
        components: {
            'content-component': ContentComponent,
            'table-component': TableComponent,
        },
        data() {
            return {
                table_header: [
                    { name: 'name', title: 'Nama', width: '30%', sort: true },
                    { name: 'suggestion', title: 'Kritik & Saran', width: '40%', sort: false },
                    { name: 'date', title: 'Tanggal', width: '20%', sort: true },
                    { name: '', title: '', width: '10%', sort: false },
                ],
                table_attributes: {
                    sort_by: 'date',
                    sort_type: 'desc',
                    search: null,
                    page: 1,
                },
                table_data: [],
            }
        },
        computed: {
            ...mapGetters({
                table: 'suggestion/getSuggestionTable',
            }),
        },
        created(){
            this.get_table(this.table_attributes)
        },
        methods: {
            get_table(value){
                this.$store.dispatch('suggestion/tableSuggestion', value).then((response) => {
                    if(response.status){
                        if(value.page == 1){
                            this.table_data = this.table.data
                        }
                        else{
                            this.table_data = [ ...this.table_data, ...this.table.data]
                        }
                        this.table_attributes = value
                    }
                })
            },
            date_format(value){
                return moment(value).format("DD-MM-YYYY HH:mm")
            },
            delete_data(id){
                let parent = this
                Swal.fire({
                    icon: 'warning',
                    title: 'Apakah anda yakin ingin menghapus data ini?',
                    reverseButtons: true,
                    showCancelButton: true,
                    cancelButtonText: parent.$root.swal('cancelButtonText'),
                    confirmButtonColor: parent.$root.swal('errorButtonColor'),
                    confirmButtonText: parent.$root.swal('deleteButtonText'),
                }).then((result) => {
                    if (result.isConfirmed) {
                        parent.$store.dispatch('suggestion/deleteSuggestion', id).then((response) => {
                            if(response.status){
                                parent.get_table(parent.table_attributes)
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Berhasil menghapus data',
                                    confirmButtonColor: parent.$root.swal('confirmButtonColor'),
                                    confirmButtonText: parent.$root.swal('doneButtonText'),
                                })
                            }
                        })
                    }
                })
            },
        }
	}
</script>