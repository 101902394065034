const SET_PROMOTION = (state, payload) => {
    state.promotion = payload.data
}

const RESET_PROMOTION_FORM = (state) => {
    state.promotion_form = {
        name: null,
        link: null,
        image: null,
        is_published: null,
    }
}

export {
    SET_PROMOTION,
    RESET_PROMOTION_FORM,
}