const getArticle = (state) => {
    return state.article
}

const getArticleForm = (state) => {
    return state.article_form
}

export {
    getArticle,
    getArticleForm,
};