const getProject = (state) => {
    return state.project
}

const getProjectForm = (state) => {
    return state.project_form
}

export {
    getProject,
    getProjectForm,
};