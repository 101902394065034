<template>
    <content-component>
        <template v-slot:table_create_button>
            <button class="btn custom-btn-02 w-100" @click="open_form_modal()">
                <i class="fas fa-plus"></i>
                Layanan
            </button>
        </template>
        <template v-slot:content_body>
            <!-- <table-component :attributes="table_attributes" :table_header="table_header" :table="table" @get_table="get_table">
                <template v-slot:table_body>
                    <tr v-for="(data, index) in table_data" :key="index">
                        <td>{{ data.name }}</td>
                        <td class="text-center">
                            <div class="d-flex justify-content-around">
                                <router-link :to="{ name : 'service.detail', params: { id: data.id }}" class="btn custom-btn-unborder-02 py-0">
                                    <i class="fas fa-eye"></i>
                                </router-link>
                                <button type="button" class="btn custom-btn-unborder-02 py-0" @click="open_form_modal(data)">
                                    <i class="fas fa-pen"></i>
                                </button>
                                <button type="button" class="btn custom-btn-unborder-02 py-0" @click="delete_data(data.id)">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </template>
            </table-component> -->
            <div class="row g-3" v-if="data.length">
                <div v-for="(service, index) in data" :key="index" class="col-12 col-lg-4">
                    <div class="row g-0 border shadow-sm">
                        <div class="col-3">
                            <div class="custom-thumbnail position-relative" v-viewer="{toolbar: false, navbar: false, keyboard: false}">
                                <div class="custom-rank custom-font-sm d-flex align-items-center justify-content-center" v-if="get_rank(service.id)">
                                    {{ get_rank(service.id) }}
                                </div>
                                <img :src="service.icon_url" alt="service-icon">
                            </div>
                        </div>
                        <div class="col-9 p-2">
                            <div class="d-flex">
                                <div class="custom-checkbox" style="width: 30px;">
                                    <input type="checkbox" :id="`rank${service.id}`" :value="service.id" v-model="ranks">
                                    <label :for="`rank${service.id}`"></label>
                                </div>
                                <div style="width: calc(100% - 60px);">{{ service.name }}</div>
                                <div class="dropdown" style="width: 30px;">
                                    <button class="btn custom-btn-unborder-02 py-0" type="button" :id="`dropdownArticle${service.id}`" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="fas fa-ellipsis-v"></i>
                                    </button>
                                    <ul class="dropdown-menu" :aria-labelledby="`dropdownArticle${service.id}`">
                                        <li>
                                            <router-link :to="{ name : 'service.detail', params: { id: service.id }}" class="dropdown-item">
                                                Detail
                                            </router-link>
                                        </li>
                                        <li><span class="dropdown-item" @click="open_form_modal(service)">Ubah</span></li>
                                        <li><span class="dropdown-item" @click="delete_data(service.id)">Hapus</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="w-100 p-3 text-center">No Data.</div>
            </div>
            <div class="custom-sticky-bottom py-3">
                <div class="row justify-content-lg-end">
                    <div class="col-12 col-lg-3">
                        <button type="button" class="btn custom-btn-02 w-100" @click="save_rank()">Simpan Peringkat</button>
                    </div>
                </div>
            </div>
        </template>
    </content-component>
    <service-form-modal ref="serviceFormModal" @close="close_modal"/>
</template>

<script>
	import ContentComponent from '@/components/external/ContentComponent'
    // import TableComponent from '@/components/external/TableComponent'
    import ServiceFormModal from './ServiceFormModal'
    import { mapGetters } from 'vuex'
    import Swal from 'sweetalert2'

	export default {
        components: {
            'content-component': ContentComponent,
            // 'table-component': TableComponent,
            'service-form-modal': ServiceFormModal,
        },
        data() {
            return {
                // table_header: [
                //     { name: 'name', title: 'Nama Layanan', width: '90%', sort: true },
                //     { name: '', title: '', width: '10%', sort: false },
                // ],
                // table_attributes: {
                //     sort_by: 'name',
                //     sort_type: 'asc',
                //     search: null,
                //     page: 1,
                // },
                // table_data: [],
                ranks: [],
            }
        },
        computed: {
            ...mapGetters({
                // table: 'service/getServiceTable',
                data: 'service/getServiceFavorite',
            }),
        },
        created(){
            // this.get_table(this.table_attributes)
            this.init()
        },
        methods: {
            // get_table(value){
            //     this.$store.dispatch('service/tableService', value).then((response) => {
            //         if(response.status){
            //             if(value.page == 1){
            //                 this.table_data = this.table.data
            //             }
            //             else{
            //                 this.table_data = [ ...this.table_data, ...this.table.data]
            //             }
            //             this.table_attributes = value
            //         }
            //     })
            // },
            init(){
                this.$store.dispatch('service/getServiceFavorite').then((response) => {
                    if(response.status){
                        this.ranks = []
                        let temp_ranks = []
                        for(let i = 0; i < response.data.length; i++){
                            if(response.data[i].rank){
                                temp_ranks.push({id: response.data[i].id, rank: response.data[i].rank})
                            }
                        }
                        temp_ranks.sort((a,b) => a.rank - b.rank)
                        temp_ranks.forEach((rank) => {
                            this.ranks.push(rank.id)
                        })
                    }
                })
            },
            open_form_modal(value){
                this.$refs.serviceFormModal.open_modal(value)
            },
            close_modal(value){
                if(value){
                    // this.table_attributes.page = 1
                    // this.get_table(this.table_attributes)
                    this.init()
                }
            },
            get_rank(value){
                let rank =this.ranks.indexOf(value)
                if(rank >= 0){
                    return rank + 1
                }else{
                    return null
                }
            },
            save_rank(){
                this.$store.dispatch('service/updateServiceRank', {ranks: this.ranks}).then((response) => {
                    if(response.status){
                        this.init()
                        Swal.fire({
                            icon: 'success',
                            title: 'Berhasil mengubah peringkat',
                            confirmButtonColor: this.$root.swal('confirmButtonColor'),
                            confirmButtonText: this.$root.swal('doneButtonText'),
                        })
                    }
                })
            },
            delete_data(id){
                let parent = this
                Swal.fire({
                    icon: 'warning',
                    title: 'Apakah anda yakin ingin menghapus data ini?',
                    reverseButtons: true,
                    showCancelButton: true,
                    cancelButtonText: parent.$root.swal('cancelButtonText'),
                    confirmButtonColor: parent.$root.swal('errorButtonColor'),
                    confirmButtonText: parent.$root.swal('deleteButtonText'),
                }).then((result) => {
                    if (result.isConfirmed) {
                        parent.$store.dispatch('service/deleteService', id).then((response) => {
                            if(response.status){
                                // parent.table_attributes.page = 1
                                // parent.get_table(parent.table_attributes)
                                parent.init()
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Berhasil menghapus data',
                                    confirmButtonColor: parent.$root.swal('confirmButtonColor'),
                                    confirmButtonText: parent.$root.swal('doneButtonText'),
                                })
                            }
                        })
                    }
                })
            },
        }
	}
</script>

<style scoped>
    .custom-rank {
        position: absolute;
        top: 0.5rem;
        left: 0.5rem;
        width: 20px;
        height: 20px;
        background-color: rgba(243, 124, 0, 1);
        color: rgba(255, 255, 255, 1);
        border-radius: 20px;
    }

    .custom-sticky-bottom {
        position: -webkit-sticky;
        position: sticky;
        bottom: -16px;
        width: 100%;
        background: #FFFFFF;
    }
</style>