import Axios from 'axios'
import { constants } from '@/config/constant'

const getArticle = async (context) => {
    const url = constants.base_url + '/v1/article/all'
    let response = await Axios.get(url)

    if(response.data.status){
        context.commit('SET_ARTICLE', response.data)
    }

    return response.data
}

const createArticle = async (context, payload) => {
    const url = constants.base_url + '/v1/article/create'
    let response = await Axios.post(url, payload, {headers:{'Content-Type': 'multipart/form-data'}})

    return response.data
}

const updateArticle = async (context, payload) => {
    const url = constants.base_url + '/v1/article/update/' + payload.id
    let response = await Axios.post(url, payload.data, {headers:{'Content-Type': 'multipart/form-data'}})

    return response.data
}

const deleteArticle = async (context, payload) => {
    const url = constants.base_url + '/v1/article/delete/' + payload
    let response = await Axios.delete(url)

    return response.data
}

const resetArticleForm = async (context) => {
    context.commit('RESET_ARTICLE_FORM')
}

export {
    getArticle,
    createArticle,
    updateArticle,
    deleteArticle,
    resetArticleForm,
}