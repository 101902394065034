const getDefaultState = () => {
	return {
		about: [],
		about_form: {
			title: null,
			description: null,
			image: null,
		}
	}
}

const default_state = getDefaultState()

export { default_state }