<template>
	<nav class="navbar navbar-expand-lg fixed-top shadow-sm px-2" v-if="user">
		<div class="navbar-brand d-flex">
			<button type="button" class="custom-sidebar-toggle" @click="sidebar_button()">
				<i class="fas fa-bars"></i>
			</button>
			<router-link :to="{name: 'dashboard'}" class="ms-2">
				<img src="@/assets/image/logo-2.png" class="custom-navbar-logo">
			</router-link>
		</div>
		<div class="d-flex">
			<div class="dropdown d-block d-lg-none me-2" v-if="notification">
				<a class="nav-link custom-navbar-link py-2 px-1" href="#" id="navbarNotificationMobileDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
					<div class="position-relative">
						<i class="fas fa-bell"></i>
						<template v-if="notification.survey_count">
							<span class="custom-notification-dot" v-if="notification.survey_count.not_paid || notification.survey_count.paid"></span>
						</template>
					</div>
				</a>
				<div class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarNotificationMobileDropdown">
					<template v-if="notification.survey_count">
						<button class="dropdown-item d-flex justify-content-between" @click="open_survey('not_paid')">
							Survey Baru:
							<div style="width: 88px; text-align: end;"><span class="custom-notification-counter">{{ notification.survey_count.not_paid }}</span></div>
						</button>
						<li><hr class="dropdown-divider"></li>
						<button class="dropdown-item d-flex justify-content-between" @click="open_survey('paid')">
							Survey Dibayar:
							<div style="width: 88px; text-align: end;"><span class="custom-notification-counter">{{ notification.survey_count.paid }}</span></div>
						</button>
					</template>
				</div>
			</div>
			<button class="navbar-toggler custom-toggler custom-navbar-link" type="button" data-bs-toggle="collapse" data-bs-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
				<i class="fas fa-cog"></i>
			</button>
		</div>
		<div class="collapse navbar-collapse" id="navbarContent">
			<ul class="navbar-nav ms-lg-auto">
				<li class="nav-item custom-nav-item dropdown d-none d-lg-block" v-if="notification">
					<a class="nav-link custom-navbar-link px-3 py-2" href="#" id="navbarNotificationDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
						<div class="position-relative">
							<i class="fas fa-bell"></i>
							<template v-if="notification.survey_count">
								<span class="custom-notification-dot" v-if="notification.survey_count.not_paid || notification.survey_count.paid"></span>
							</template>
						</div>
					</a>
					<div class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarNotificationDropdown">
						<template v-if="notification.survey_count">
							<button class="dropdown-item d-flex justify-content-between" @click="open_survey('not_paid')">
								Survey Baru:
								<div style="width: 88px; text-align: end;"><span class="custom-notification-counter">{{ notification.survey_count.not_paid }}</span></div>
							</button>
							<li><hr class="dropdown-divider"></li>
							<button class="dropdown-item d-flex justify-content-between" @click="open_survey('paid')">
								Survey Dibayar:
								<div style="width: 88px; text-align: end;"><span class="custom-notification-counter">{{ notification.survey_count.paid }}</span></div>
							</button>
						</template>
					</div>
				</li>
				<li class="nav-item custom-nav-item dropdown">
					<a class="nav-link custom-navbar-link py-3 px-1 py-lg-1" href="#" id="navbarUserDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
						<div class="d-flex align-items-center">
							<div class="custom-navbar-avatar shadow-sm me-2">
								<img :src="user.photo_url" alt="customer-image">
							</div>
							{{ user.name }}
							<i class="fas fa-angle-down ms-1"></i>
						</div>
					</a>
					<div class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarUserDropdown">
						<button class="dropdown-item" @click="open_change_password_modal()">Ubah Password</button>
						<li><hr class="dropdown-divider"></li>
						<button class="dropdown-item" @click="signOut()">Logout</button>
					</div>
				</li>
			</ul>
		</div>
	</nav>
	<modal-component :modal_status="change_password_modal">
        <template v-slot:modal_header>
            <div class="modal-title custom-font-lg custom-font-bold">Ubah Password</div>
            <button type="button" class="btn-close" @click="close_change_password_modal()"/>
        </template>
        <template v-slot:modal_body>
            <div class="mb-3" :class="validation.firstError('form.old_password') ? 'custom-form-error' : ''">
                <label class="form-label">Password Lama</label>
                <input type="password" class="form-control custom-form-control" placeholder="Password Lama" autocomplete="off" v-model="form.old_password">
                <div class="custom-form-error-message">{{ validation.firstError('form.old_password') }}</div>
            </div>
			<div class="mb-3" :class="validation.firstError('form.new_password') ? 'custom-form-error' : ''">
                <label class="form-label">Password Baru</label>
                <input type="password" class="form-control custom-form-control" placeholder="Password Baru" autocomplete="off" v-model="form.new_password">
                <div class="custom-form-error-message">{{ validation.firstError('form.new_password') }}</div>
            </div>
			<div class="mb-3" :class="validation.firstError('form.confirm_new_password') ? 'custom-form-error' : ''">
                <label class="form-label">Konfirmasi Password Baru</label>
                <input type="password" class="form-control custom-form-control" placeholder="Password Lama" autocomplete="off" v-model="form.confirm_new_password">
                <div class="custom-form-error-message">{{ validation.firstError('form.confirm_new_password') }}</div>
            </div>
        </template>
        <template v-slot:modal_footer>
            <button type="button" class="btn custom-btn-outline-02" @click="close_change_password_modal()">Tutup</button>
            <button type="button" class="btn custom-btn-02" @click="submit_change_password()">Ubah</button>
        </template>
    </modal-component>
</template>

<script>
	import ModalComponent from '@/components/external/ModalComponent'
	import { mapGetters } from 'vuex'
    import { Validator } from 'simple-vue3-validator'
	import moment from 'moment'
    import Swal from 'sweetalert2'

    export default {
		props: ['user', 'sidebar', 'notification'],
		emits: ['clicked'],
		components: {
            'modal-component': ModalComponent,
        },
		data() {
            return {
                change_password_modal: false,
            }
        },
		computed: {
            ...mapGetters({
                form: 'auth/getChangePasswordForm',
            }),
        },
		validators: {
            'form.old_password': function(value){
                return Validator.value(value).required('Wajib di isi.')
            },
			'form.new_password': function(value){
                return Validator.value(value).required('Wajib di isi.').minLength(6, 'Minimal 6 Karakter.').maxLength(16, 'Maksimal 16 Karakter.').custom(function (){
                    if(!(/\d/.test(value))){
                        return 'Password harus memiliki 1 angka.'
                    }
                    if(!(/[A-Z]/.test(value))){
                        return 'Password harus memiliki 1 huruf kapital.'
                    }
                })
            },
			'form.confirm_new_password, form.new_password': function(confirm_new_password, new_password){
                return Validator.value(confirm_new_password).required('Wajib di isi.').match(new_password, 'Konfirmasi password tidak sama.')
            },
        },
		methods: {
			sidebar_button() {
				this.$emit('clicked', !this.sidebar)
			},
			open_change_password_modal(){
                this.change_password_modal = true
            },
            close_change_password_modal(){
                this.$store.dispatch('auth/resetChangePasswordForm')
                this.change_password_modal = false
            },
			submit_change_password(){
                let parent = this
                parent.$validate().then(function (success){
                    if(success){
						Swal.fire({
							icon: 'warning',
							title: 'Apakah anda yakin ingin mengubah password?',
							text: 'Melakukan perubahan password akan mengharuskan anda melakukan login ulang.',
							reverseButtons: true,
							showCancelButton: true,
							cancelButtonText: parent.$root.swal('cancelButtonText'),
							confirmButtonColor: parent.$root.swal('errorButtonColor'),
							confirmButtonText: parent.$root.swal('deleteButtonText'),
						}).then((result) => {
							if (result.isConfirmed) {
								parent.$store.dispatch('auth/changePassword', parent.form).then((response) => {
									if(response.status){
										parent.close_change_password_modal()
										parent.signOut()
										Swal.fire({
											icon: 'success',
											title: 'Berhasil mengubah password',
											confirmButtonColor: parent.$root.swal('confirmButtonColor'),
											confirmButtonText: parent.$root.swal('doneButtonText'),
										})
									}
								})
							}
						})
                    }
					else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: parent.$root.swal('errorValidationText'),
                            confirmButtonColor: parent.$root.swal('errorButtonColor'),
                            confirmButtonText: parent.$root.swal('errorButtonText'),
                        })
                    }
                })
			},
			open_survey(value){
				let data = {
					status: value,
					read_on: moment().unix() * 1000
				}
				
				this.$store.dispatch('notification/notificationUpdateSurveyCount', data).then((response) => {
					if(response.status){
						if(this.$route.name == 'survey' && this.$route.params.status == value){
							this.$store.dispatch('utilities/isRefresh')
						}
						else{
							this.$router.push({name: 'survey', params: {status: value}})
						}
					}
				})
			},
			signOut(){
                this.$store.dispatch('auth/signOut').then((response) => {
					if(response){
						this.$router.push({name: 'sign_in'})
					}
				})
            },
		}
	}
</script>

<style scoped>
	.custom-notification-dot {
		position: absolute;
		top: 0;
		/* right: -6px; */
		background-color: rgba(255, 100, 100, 1);
		width: 6px;
		height: 6px;
		border-radius: 6px;
	}
	.custom-notification-counter {
		padding: 0 0.5rem;
		background-color: rgba(243, 124, 0, 1);
		color: rgba(255, 255, 255, 1);
		border-radius: 40px;
		text-align: right;
	}
</style>