const copy = (o) => {
	return JSON.parse(JSON.stringify(o));
}

const currency = (value) => {
	return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(value).replace(/,00/g,'')
}

const swal = (value) => {
	if(value == 'errorValidationText'){
		return 'Harap isi semua data dengan benar.'
	}
	if(value == 'deleteButtonText'){
		return 'Hapus'
	}
	if(value == 'confirmButtonColor'){
		return '#F37C00'
	}
	if(value == 'confirmButtonText'){
		return 'Ya'
	}
	if(value == 'doneButtonText'){
		return 'Selesai'
	}
	if(value == 'cancelButtonText'){
		return 'Batal'
	}
	if(value == 'errorButtonColor'){
		return '#FF6464'
	}
	if(value == 'errorButtonText'){
		return 'Tutup'
	}
	
	return false
}


export {
	copy,
	currency,
	swal,
}