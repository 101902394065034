const SET_SERVICE = (state, payload) => {
    state.service = payload.data
}

const SET_SERVICE_FAVORITE = (state, payload) => {
    state.service_favorite = payload.data
}

const SET_SERVICE_TABLE = (state, payload) => {
    state.service_table = payload.data
}

const SET_SERVICE_DETAIL = (state, payload) => {
    state.service_detail = payload.data
}

const RESET_SERVICE_DETAIL = (state) => {
    state.service_detail = null
}

const RESET_SERVICE_FORM = (state) => {
    state.service_form = {
        name: null,
        icon: null,
    }
}

const RESET_PROBLEM_FORM = (state) => {
    state.problem_form = {
        service_id: null,
        name: null,
    }
}

const RESET_SERVICE_DETAIL_FORM = (state) => {
    state.service_detail_form = {
        service_id: null,
        name: null,
    }
}

const RESET_SERVICE_PRICE_FORM = (state) => {
    state.service_price_form = {
        service_id: null,
        product: null,
        unit: null,
        price: null,
        image: null,
    }
}

export {
    SET_SERVICE,
    SET_SERVICE_FAVORITE,
    SET_SERVICE_TABLE,
    SET_SERVICE_DETAIL,
    RESET_SERVICE_DETAIL,
    RESET_PROBLEM_FORM,
    RESET_SERVICE_FORM,
    RESET_SERVICE_DETAIL_FORM,
    RESET_SERVICE_PRICE_FORM,
}