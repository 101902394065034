const getFAQTable = (state) => {
    return state.faq_table
}

const getFAQForm = (state) => {
    return state.faq_form
}

export {
    getFAQTable,
    getFAQForm,
};