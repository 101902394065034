import Axios from 'axios'
import { constants } from '@/config/constant'

const countSurvey = async () => {
    const url = constants.base_url + '/v2/survey/count'
    let response = await Axios.get(url)

    return response.data
}

const tableSurvey = async (context, payload) => {
    const url = constants.base_url + '/v2/survey/table'
    let response = await Axios.get(url, {headers:{'custom_loading': true}, params: payload})

    if(response.data.status){
        context.commit('SET_SURVEY_TABLE', response.data)
    }

    return response.data
}

const detailSurvey = async (context, payload) => {
    const url = constants.base_url + '/v2/survey/detail/' + payload
    let response = await Axios.get(url)

    if(response.data.status){
        context.commit('SET_SURVEY_DETAIL', response.data)
    }

    return response.data
}

const getProblemList = async (context, payload) => {
    const url = constants.base_url + '/v1/problem/all'
    let response = await Axios.get(url, { params: { array_id: payload } })

    return response.data
}

const getServiceDetailList = async (context, payload) => {
    const url = constants.base_url + '/v1/service_detail/all'
    let response = await Axios.get(url, { params: { array_id: payload } })

    return response.data
}

const getPaymentStatus = async (context, payload) => {
    const url = constants.base_url + '/v1/payment/check_status'
    let response = await Axios.post(url, payload)

    return response.data
}

const updateSurveyPaid = async (context, payload) => {
    const url = constants.base_url + '/v1/payment/update/admin_paid'
    let response = await Axios.post(url, payload)

    return response.data
}

const updateSurveyProgress = async (context, payload) => {
    const url = constants.base_url + '/v1/survey/update/progress/' + payload
    let response = await Axios.post(url)

    return response.data
}

const updateSurveyComplete = async (context, payload) => {
    const url = constants.base_url + '/v1/survey/update/complete/' + payload.id
    let response = await Axios.post(url, payload.data, {headers:{'Content-Type': 'multipart/form-data'}})

    return response.data
}

const deleteSurvey = async (context, payload) => {
    const url = constants.base_url + '/v1/survey/delete/' + payload
    let response = await Axios.delete(url)

    return response.data
}

const resetSurveyDetail = async (context) => {
    context.commit('RESET_SURVEY_DETAIL')
}

const resetSurveyForm = async (context) => {
    context.commit('RESET_SURVEY_FORM')
}

export {
    countSurvey,
    tableSurvey,
    detailSurvey,
    getProblemList,
    getServiceDetailList,
    getPaymentStatus,
    updateSurveyPaid,
    updateSurveyProgress,
    updateSurveyComplete,
    deleteSurvey,
    resetSurveyDetail,
    resetSurveyForm,
}