<template>
	<!-- Content wrapper -->
	<div class="row">
		<div class="col-12">
			<div class="custom-content-card">
				<!-- Content header -->
				<div class="custom-content-header p-3">
					<div class="row align-items-end">
						<div class="col-12 col-lg-9">
							<div class="custom-font-xl custom-font-bold">{{ title }}</div>
							<ol class="breadcrumb custom-breadcrumb p-0 m-0">
								<li v-for="(breadcrumb, index) in breadcrumbs" :key="index" :class="index == (breadcrumbs.length - 1) ? 'breadcrumb-item active' : 'breadcrumb-item'">
									{{ breadcrumb }}
								</li>
							</ol>
						</div>
						<div class="col-12 col-lg-3 mt-3 mt-lg-0" v-if="has_create_button_slot">
							<slot name="table_create_button"></slot>
						</div>
					</div>
				</div>
				<!-- Content Body -->
				<div class="p-3">
					<slot name="content_body"></slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		computed: {
			title() {
				return this.$route.meta.title
			},
            breadcrumbs() {
                let path = this.$route.meta.breadcrumbs.split(".")
				return path
			},
            has_create_button_slot() {
                return !!this.$slots.table_create_button
            },
		}
	}
</script>