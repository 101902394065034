import Axios from 'axios'
import { constants } from '@/config/constant'

const countUser = async () => {
    const url = constants.base_url + '/v1/user/count'
    let response = await Axios.get(url)

    return response.data
}

const tableUser = async (context, payload) => {
    const url = constants.base_url + '/v1/user/table'
    let response = await Axios.get(url, {headers:{'custom_loading': true}, params: payload})

    if(response.data.status){
        context.commit('SET_USER_TABLE', response.data)
    }

    return response.data
}

export {
    countUser,
    tableUser,
}