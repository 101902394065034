const SET_ARTICLE = (state, payload) => {
    state.article = payload.data
}

const RESET_ARTICLE_FORM = (state) => {
    state.article_form = {
        title: null,
        description: null,
        image: null,
        link: null,
        is_published: null,
    }
}

export {
    SET_ARTICLE,
    RESET_ARTICLE_FORM,
}