<template>
    <div class="input-group">
        <span class="input-group-text">Rp.</span>
        <input ref="inputRef" type="text" class="form-control custom-form-control" :placeholder="placeholder" :disabled="disabled"/>
    </div>
</template>

<script>
    import { watch } from 'vue'
    import { useCurrencyInput } from 'vue-currency-input'

    export default {
        name: 'CurrencyInput',
        props: ['modelValue', 'options', 'placeholder', 'disabled'],

        setup(props) {
            let config = {
                "locale": "de-DE",
                "currency": "USD",
                "currencyDisplay": "hidden",
                "hideCurrencySymbolOnFocus": true,
                "hideGroupingSeparatorOnFocus": true,
                "hideNegligibleDecimalDigitsOnFocus": true,
                "autoDecimalDigits": false,
                "autoSign": true,
                "useGrouping": true,
                "accountingSign": false,
            }

            const {inputRef, setValue, setOptions} = useCurrencyInput(config)

            watch(
                () => props.modelValue,
                (value) => {
                    setValue(value)
                }
            )

            watch(
                () => props.options,
                (options) => {
                    setOptions(Object.assign(config, options))
                }
            )

            return {inputRef}
        }
    }
</script>